import { createSlice } from "@reduxjs/toolkit";

const  initialState ={
    backBtn: "",
    backBtnLabel: ""

}
const headerSlice = createSlice({
    name: 'headerMenuLinking',
    initialState,
    reducers: {
        setBackMenu: (state, action) => {
            state.backBtn = action.payload.backBtn;
            state.backBtnLabel = action.payload.backBtnLabel;
        }
    }
})
export const headerMenuActions = headerSlice.actions;
export default headerSlice.reducer;