import React, { useEffect, useState } from 'react';
import * as PR from "../../prime-react/index";
import { eventIcon } from "../../assets/images/index";

const LazyImage = ({ src, alt }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        const importImage = async () => {
            try {
                const image = await import(`../../assets/images/sports/${src}`);
                setImageSrc(image?.default || image);
                setError(false);
            } catch (error) {
                console.error('Error importing image:', error);
                setError(true);
            }
        };

        importImage();
    }, [src]);

    if (error || !imageSrc) {
        return <PR.Image src={eventIcon} alt="Sport" />
    }

    return (
        <PR.Image src={imageSrc} alt={alt} />
    )
};

export default LazyImage;
