import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";

const newsNavigation = secureLocalStorage.getItem('newsNavigation');

const initialNewsState = {
   activeIndex:newsNavigation?.activeIndex,
   newsNavigation: newsNavigation
}

const newsSlice = createSlice({
    name: 'news',
    initialState: initialNewsState,
    reducers: {
        updateTab(state, action){
            if(!newsNavigation){
                const newsNavigation = {
                    activeIndex:action.payload.activeIndex
                }
                secureLocalStorage.setItem("newsNavigation", newsNavigation)
            }
            if(state.newsNavigation){
                state.newsNavigation.activeIndex = action.payload.activeIndex
                secureLocalStorage.setItem("newsNavigation", state.newsNavigation)
            }
            state.activeIndex = action.payload.activeIndex
        }
    }
})

export const newsActions = newsSlice.actions;
export default newsSlice.reducer;