import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import "./SportingSanction.scss";
import Layout from "../../../layouts/Layout";
import * as PR from "../../../prime-react/index";
import {
  slide1,
  slide2,
  news1,
  news2,
  news3,
  news4,
  news5,
  news6,
  news7,
  newView1,
  newView2,
  Avatar,
  newView3,
  newView4,
  newView5,
  newView6,
} from "../../../assets/images/index";
import { getNews } from "../../../services/api";
import { pagecount, queryLimit } from "../../../utils/reuse";
import MostViewed from "../../../shared/MostViewed/MostViewed";
import TopCategories from "../../../shared/TopCategories/TopCategories";
import dbData from "../../../assets/data/db.json";
import moment from "moment";
import { useToast } from "../../../context/ToastContext";
import NewsItem from "../../../shared/News/NewsItem";

const SportingSanction = () => {
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const sessionData = useSelector(state => state.auth.sessionData);
  const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
  const [dates, setDates] = useState(null);
  const [filteredNewsData, setFilteredNewsData] = useState([]);
  const [mostViewedData, setMostViewedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); 
  const navigate = useNavigate();
  const pageCount = pagecount;
  const [error, setError] = useState(null)

  const imageMap = {
    news1,
    news2,
    news3,
    news4,
    news5,
    news6,
    news7,
    newView1,
    newView2,
    newView3,
    newView4,
    newView5,
    newView6,
    slide1,
    slide2,
    Avatar
  };
  const trigger = useRef(null);

  useEffect(() => {
    setMostViewedData(dbData.mostViewed);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getNewsListHandler(searchedNews.current);
    // eslint-disable-next-line
  },[dates])

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      getNewsListHandler(searchedNews.current,newPage);
    }
  };

  const handleCategoryClick = (categoryId, categoryName) => {
    const formattedCategory = categoryName?.replace(/ /g, '-')?.replace(/-+$/, '');
    navigate(`/news-category?categoryID=${categoryId}&categoryName=${formattedCategory}`);
  };

  const getNewsListHandler = useCallback((searchQuery, page = 1) => {
    setLoading(true);
    const obj = {
      offset: (page - 1) * pageCount,
      limit: pageCount,
      type: 2 
    };

    if (searchQuery) {
      obj.search = searchQuery;
    }
    if (dates && dates?.length > 0) {
      const startDate= moment(dates[0]).format('YYYY-MM-DD');
      const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
      obj.startDate = startDate;
      obj.endDate = endDate;
    }
    getNews(obj, headers, dispatch, (response) => {
      if (response.result === 'SUCCESS') {
        const responseData = response.data;
        const totalPages = Math.ceil(responseData?.length / pageCount);
        setFilteredNewsData(responseData);
        setTotalPages(totalPages);
      } else {
        const error = response.error;
        showToast(error.severity, error.errorMsg || error.summary);
      }
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [pageCount, dates, headers, dispatch, showToast]);
  const searchedNews = useRef('')

  const searchHandler =(e) => {
    let shouldTriggerRefetch = false;
        const query = e.target.value
        if (query.length < 3) {
            setSearchQuery(query)
            
            if(searchedNews.current !== ''){
                searchedNews.current = ''
                shouldTriggerRefetch = true;
            }
            setError('Please enter atleast 3 characters');
            setTimeout(() => {
                setError(null);
            }, 2500);
        } else if (query?.length <= queryLimit) {

            setError(null)
            setSearchQuery(query)
            if(searchedNews.current !== query){
                searchedNews.current = query
                shouldTriggerRefetch = true;
            }

        }else{
            setError(`Search can be at most ${queryLimit} characters long`)
            setTimeout(() => {
                setError(null)
            }, 2500)
        }
        if(shouldTriggerRefetch){

            if (trigger.current) clearTimeout(trigger.current);
            trigger.current = setTimeout(() => {
                getNewsListHandler(searchedNews.current);
            }, 1000);
        }
  }

  return (
    <>
      <Layout>
        <div className="back-button">
          <Link to="/news">
            <i className="pi pi-arrow-left"></i>
            Back to News
          </Link>
        </div>
        <section className="news-tab">
          <div className="grid">
            <div className="col-12">
              <div className="new-list-section">
                <div className="flex align-items-center justify-content-between">
                  <div className="left">
                    <h1>All Sanctions</h1>
                  </div>
                  <div className="right flex gap-3">
                    <div className="card">
                      <div className="flex align-items-center search-bar">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            placeholder="Search.."
                            className="search-box w-12"
                            value={searchQuery}
                            onChange={searchHandler}
                          />
                        </span>
                      </div>
                      {error && <p className="error-message mt-2">{error}</p>}
                    </div>
                    <div className="card" style={{ position: "relative" }}>
                      <PR.Calendar
                        value={dates}
                        onChange={(e) => setDates(e.value)}
                        iconPos="left"
                        readOnlyInput
                        showIcon
                        selectionMode="range"
                        className="date-calendar"
                        placeholder="Select Date"
                        showButtonBar
                        dateFormat="dd/mm/yy"
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                <div className="list-wrapper">
                  {loading ? (
                    <div className="loading">
                      <i className="pi pi-spin pi-spinner"></i>
                    </div>
                  ) : (!filteredNewsData || filteredNewsData?.length === 0) ? (
                    <div className="flex justify-content-center">
                        <p className="no-data m-0 text-bluegray-500">
                            No records found
                        </p>
                    </div>
                  ) : (
                    filteredNewsData?.map((news) => {
                      return (
                        <React.Fragment key={news.id}>
                          <NewsItem
                            news={news}
                            Avatar={Avatar}
                            newsType="sportingsanctions"                       
                          />
                          <div className="hr-line mt-4 mb-4"></div>
                        </React.Fragment>
                      );
                    })
                  )}
                </div>
                {filteredNewsData?.length > 0 && (
                  <div className="pagination">
                    <div className="grid grid-nogutter align-items-center justify-content-between">
                      <span>Page {currentPage}</span>
                      <ul className="flex list-none align-items-center gap-3">
                        <li
                          className={currentPage === 1 ? "disabled" : ""}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </li>
                        <li
                          className={currentPage === totalPages ? "disabled" : ""}
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-3" style={{display:'none'}}>
              <MostViewed mostViewedData={mostViewedData} imageMap={imageMap} />
              <TopCategories
                heading={"Top news categories"}
                handleCategoryClick={handleCategoryClick}
              />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default SportingSanction;
