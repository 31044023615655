import React from "react";
import * as PR from "../prime-react/index";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const MenuBar = () => {
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    const navigatePath = (selectedPath) => {
        pathname !== selectedPath && navigate(selectedPath)
    }
    const role = useSelector(state => state.auth.role);

    const allItems = [
        {
            id: 'home',
            label: 'Home',
            className: (pathname === '/home') ? 'p-menuitem-active' : '',
            command: () => navigatePath('/home'),
            isVisible: true
        },
        {
            id: 'alerts',
            label: 'Alerts',
            className: (pathname === '/alerts') ? 'p-menuitem-active' : '',
            command: () => navigatePath('/alerts'),
            isVisible: true
        },
        {
            id: 'analytics',
            label: 'My Analytics',
            className: (pathname === '/my-analytics') ? 'p-menuitem-active' : '',
            command: () => navigatePath('/my-analytics'),
            isVisible: (role === 'member' || role === 'managedmember') ? true: false
        },
        {
            id: 'caseupdates',
            label: 'Case Updates',
            className: (pathname === '/case-updates') ? 'p-menuitem-active' : '',
            command: () => navigatePath('/case-updates'),
            isVisible: (role === 'member' || role === 'managedmember') ? true: false
        },
        {
            id: 'reports',
            label: 'Reports',
            className: (pathname === '/reports') ? 'p-menuitem-active' : '',
            command: () => navigatePath('/reports'),
            isVisible: role === 'regulator' || role === 'governingbody' ? true: false
        },
        {
            id: 'news',
            label: 'News',
            className: (pathname === '/news') ? 'p-menuitem-active' : '',
            command: () => navigatePath('/news'),
            isVisible: true
        },
        {
            id: 'externallinks',
            label: 'External Links',
            className: (pathname === '/external-links') ? 'p-menuitem-active' : '',
            command: () => navigatePath('/external-links'),
            isVisible: true
        },
    ];

    const items = allItems.filter(item => item.isVisible);

    return (
        <>
            <div className="menu-section">
                <PR.Menubar model={items} />
            </div>
        </>
    );
};

export default MenuBar;