import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as PR from "../../prime-react/index";

const DownloadPDF = ({ contentId, fileName, buttonLabel, buttonClassName, headerText }) => {
  const handleDownloadPDF = async () => {
    const buttons = document.querySelectorAll(".hide-on-download");
    buttons.forEach(button => (button.style.display = "none"));
    const content = document.getElementById(contentId);
    const canvas = await html2canvas(content, { scale: 2 });
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const margin = 5; // Left and right margin
    const headerHeight = 20;
    const bottomMargin = 10; // Bottom margin
    const contentStartPosition = headerHeight + 10; // Space for the header and padding
    const pageContentHeight = pdfHeight - contentStartPosition - bottomMargin; // Height available for content on each page

    let imgHeight = (canvas.height * pdfWidth) / canvas.width; // Scale the image height to fit PDF width
    let position = 0; // Initial position to start rendering content

    pdf.setFontSize(10);
    pdf.text(headerText, margin, headerHeight); // Add header text on the first page

    while (position < imgHeight) {
      // Calculate the height of the remaining content to render
      const remainingHeight = imgHeight - position;
      const renderHeight = Math.min(pageContentHeight, remainingHeight); // Render only content that fits on the current page

      // Create a portion of the canvas for the current page's content
      const canvasPart = document.createElement("canvas");
      const context = canvasPart.getContext("2d");
      canvasPart.width = canvas.width;
      canvasPart.height = Math.min(remainingHeight, pageContentHeight) * (canvas.width / pdfWidth); // Scale canvas part height

      // Draw the current portion of the original canvas
      context.drawImage(
        canvas,
        0,
        position * (canvas.width / pdfWidth), // Start Y position on the original canvas
        canvas.width,
        renderHeight * (canvas.width / pdfWidth), // Height to draw from the original canvas
        0,
        0,
        canvasPart.width,
        canvasPart.height
      );

      const partImgData = canvasPart.toDataURL("image/png");

      pdf.addImage(partImgData, "PNG", margin, contentStartPosition, pdfWidth - 2 * margin, renderHeight);

      position += renderHeight; // Move position to the next page

      if (position < imgHeight) {
        pdf.addPage(); // Add new page if there's more content
        pdf.setFontSize(10); // Reset the font size for the new page
        pdf.text(headerText, margin, headerHeight); // Add header on each new page
      }
    }

    pdf.save(fileName);

    buttons.forEach(button => (button.style.display = ""));
  };

  return (
    <PR.Button
      icon="pi pi-download"
      label={buttonLabel}
      className={buttonClassName}
      onClick={handleDownloadPDF}
    />
  );
};

export default DownloadPDF;
