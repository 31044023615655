import React from "react";
import "./ExternalLinks.scss";
import MenuBar from "../../layouts/MenuBar";
import { Link } from 'react-router-dom';
import Layout from "../../layouts/Layout";
import { useSelector } from 'react-redux';
import { linksData } from "../../assets/data/externaLinks";
import { getSportIcon } from "../../utils/reuse";

const ExternalLinks = () => {
    const role = useSelector(state => state.auth.role);

    return (
        <>
            <Layout>
                <MenuBar/>
                <section className={`external-links-tab ${role === 'member' || role === 'managedmember'  ? 'member-links' : 'reg-links'}`}>
                    <div className="m-0">
                        <h1>External Links</h1>
                    </div>
                    <div className="grid mt-2">
                        <div className="col-12">
                            <div className="links-left-section">
                                {/* Links */}
                                {linksData.map(( (item, index) => {
                                    return(
                                        <div className="link-container" key={index}>
                                        <div className="flex gap-3">
                                            <div className="icon">
                                                <div className='sports-icon'>{getSportIcon(item?.sportID + 'b')}</div>
                                            </div>
                                            <div className="link-info">
                                                <h4>{item?.sportName}</h4>
                                                <div className="flex flex-column gap-2">
                                                    {item.links.map((linkName, index)=> (
                                                        <Link key={index} to={linkName?.link} className="links flex flex-row align-items-center gap-2" target="_blank"><p>{linkName?.label}</p><i className="pi pi-arrow-up-right"></i></Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }))}
                               
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        </>
    );
};

export default ExternalLinks;