import React from "react";
import "./Help.scss";
import Layout from "../../layouts/Layout";
import { useSelector } from "react-redux";
import AnalystMemberHelp from "./AnalystMemberHelp";
import RegulatorHelp from "./RegulatorHelp";


const Help = () => {
    const isManagedMember = useSelector(state => state.auth.role) === 'managedmember';
    const isMember = useSelector(state => state.auth.role) === 'member';
    const isAnalyst = useSelector(state => state.auth.role) === 'analyst';
    const isRegulator = useSelector(state => state.auth.role) === 'regulator';
    const isGoverningBody = useSelector(state => state.auth.role) === 'governingbody';
    return (
        <>
            <Layout>
                <section className="help-page-section">
                    <div className="help-wrapper">
                        <div className="m-0">
                            <h1>Help</h1>
                        </div>
                        <div className="help-tabs-section">
                            { (isManagedMember || isMember || isAnalyst) && <AnalystMemberHelp />}
                            { (isRegulator || isGoverningBody) && <RegulatorHelp />}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default Help;