import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

const BackMenuLayout = ({children}) => {
    useEffect(() => {
      const currentVersion = process.env.REACT_APP_VERSION; 
      const cachedVersion = localStorage.getItem('appVersion');

        if (!cachedVersion || (cachedVersion !== currentVersion)) {
          // Clear outdated cache and update to the new version
          localStorage.setItem('appVersion', currentVersion);
          // Avoid infinite reloads
          if (cachedVersion) {
            window.location.reload(true);
          }
        }
      }, [children]);
    return (
        <>
            <div className="main">
                <Header/>
                    {children}
                <Footer/>
            </div>
        </>
    );
};

export default BackMenuLayout;