import { useState } from 'react';
import { useToast } from '../context/ToastContext';
import { exportModifyDateTime } from './reuse';

// Custom hook for CSV export logic
export const useCSVExport = (processedOptions, sortOrder, headers, dispatch, exportAlerts, handleExportCSV) => {
    const [exportLoading, setLoading] = useState(false);
    const { showToast } = useToast();

    const exportCSV = (e) => {
        setLoading(true);
        e.preventDefault();

        const obj = {
            ...processedOptions,
            sort: sortOrder.current === '' ? 'desc' : 'asc'
        };

        exportAlerts(obj, headers, dispatch, response => {
            setLoading(false);
            if (response.result === 'SUCCESS') {
                const data = response.data;
                const formattedAlerts = data?.map(alert => {
                    const formattedAlert = Object.keys(alert).reduce((acc, key) => {
                        if (Array.isArray(alert[key])) {
                            const isArrayOfPrimitives = alert[key].every(item => typeof item !== 'object');
                            if (isArrayOfPrimitives) {
                                acc[key] = alert[key].join(', ');
                            } else {
                                acc[key] = alert[key]; // Keep arrays of objects as-is
                            }
                        } else if (typeof alert[key] === 'string' && key === 'description') {
                            acc[key] = alert[key].replace(/\n/g, ' ');
                        } else {
                            acc[key] = alert[key];
                        }
                        return acc;
                    }, {});
                    
                    return {
                        ...formattedAlert,
                        createdAt: exportModifyDateTime(alert.createdAt),
                    };
                });

                if (formattedAlerts?.length > 0) {
                    handleExportCSV(formattedAlerts);
                    showToast("success", "Exported successfully");
                } else {
                    showToast("warn", "No records found");
                }
            } else {
                const error = response?.error;
                showToast("error", error?.errorMsg || error?.summary);
            }
        });
    };

    return {
        exportLoading,
        exportCSV
    };
};
