import * as Yup from 'yup';

export const validationSchema = () => {
    return Yup.object().shape({
        newPassword: Yup.string()
        .required('Password is required')
        .max(20, 'Password must not exceed 20 characters')
        .matches(
            /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@$%&*()-])[a-zA-Z0-9!@$%&*()-]{8,}$/,
            "Password must contain at least one uppercase letter, one number, one special character from !@$%&*-(), and be at least 8 characters long."
        ),
        confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"),null], "Passwords must match" ).required('Confirm password is required')
    })
}

export const initialValues = {
    newPassword: '',
    confirmPassword:''
}