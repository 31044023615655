import { videoPoster1, regulatorAlertsWorldMap, regulatorSignIn, regulatorReports, videoPoster3, videoPoster4 } from "../../assets/videos";
import * as PR from "../../prime-react/index";
import { forgotPasswordScreen, otpScreen, loginScreen } from '../../assets/images'
import { Link } from "react-router-dom";
import { supportEmail } from "../../utils/reuse";

const RegulatorHelp = () => {
    return (
        <>
            <PR.TabView className="vertical-tabs">
                <PR.TabPanel header="1. First time login and password reset">
                    <div className="help-content">
                        <p>Please find below a series of step-by-step video tutorials to assist with navigating and operating
                        within the IBIA regulator dashboard.</p>
                        <h2 className="mt-3">1. First time login and password reset</h2>
                        <ul className="mt-2">
                            <li>When your account is activated within the system, you will be notified via an email that will
                                contain a link to create your password, as demonstrated in the video below:</li>
                        </ul>
                        <div className="video-wrapper">
                            <video className='video' controls poster={videoPoster1}>
                                <source src={regulatorSignIn} type="video/mp4" />
                            </video>
                        </div>
                        <ul className="mt-2">
                            <li>Should you need to reset your password in future, this can be done by navigating to the
                                domain <Link to={`https://alert.ibia.bet/`} target="_blank"> https://alert.ibia.bet/</Link> and selecting ‘Can’t access your account?’.</li>
                        </ul>
                        <div className="text-center login-images">
                            <PR.Image src={loginScreen} alt="Login Screen" />
                            <PR.Image src={otpScreen} alt="OTP Screen" />
                            <PR.Image src={forgotPasswordScreen} alt="forgot Password Screen" />
                        </div>
                        <p>This will prompt a 'Forgot Password' screen. Enter your email address for the email that you currently use in JIRA, and an email will be sent to that address which includes a link for you to set your password and login. Once these credentials have been entered a One-Time Password (OTP) will also be required for login, which will also be sent to your email address. </p>
                        <p>Should you have any issues with logging in for the first time, please don't hesitate to contact <Link to={`mailto:${supportEmail}`}>{supportEmail}</Link>. </p>
                    </div>
                </PR.TabPanel>
                <PR.TabPanel header="2. Alerts and the World map">
                    <div className="help-content">
                        <h2>2. Alerts and the World map</h2>
                        <div className="video-wrapper">
                            <video className='video' controls poster={videoPoster3}>
                                <source src={regulatorAlertsWorldMap} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </PR.TabPanel>
                <PR.TabPanel header="3. Reports and News">
                    <div className="help-content">
                        <h2>3. Reports and News</h2>
                        <div className="video-wrapper">
                            <video className='video' controls poster={videoPoster4}>
                                <source src={regulatorReports} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </PR.TabPanel>
                {/* <PR.TabPanel header="4. External links">
                    <div className="help-content">
                        <h2>4. External links</h2>
                        <div className="video-wrapper">
                            <video className='video' controls poster={videoPoster2}>
                                <source src={regulatorReports} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </PR.TabPanel> */}

            </PR.TabView>
        </>
    )

}

export default RegulatorHelp;