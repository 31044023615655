import {BrowserRouter as Router } from 'react-router-dom';
import { useState } from 'react';
import PublicRoutes from './PublicRoutes';
import MemberRoutes from './MemberRoutes';
import { useSelector } from 'react-redux';
import AnalystRoutes from './AnalystRoutes';
import RegulatorRoutes from './RegulatorRoutes';
import GoverningBodyRoutes from './GoverningBodyRoutes';

const Navigation = () => {
    const isManagedMember = useSelector(state => state.auth.role) === 'managedmember';
    const isMember = useSelector(state => state.auth.role) === 'member';
    const isAnalyst = useSelector(state => state.auth.role) === 'analyst';
    const isRegulator = useSelector(state => state.auth.role) === 'regulator';
    const isGoverningBody = useSelector(state => state.auth.role) === 'governingbody';
    const isAuthenticated = useSelector(state => state.auth.isLoggedIn);

    const [activeIndex, setActiveIndex] = useState(0)
    const handleTabChange = (index) => {
        setActiveIndex(index)
    }
    return (
        <Router>
            {!isAuthenticated && <PublicRoutes />}
            {isAnalyst && <AnalystRoutes />}
            {(isMember || isManagedMember) && <MemberRoutes handleTabChange={handleTabChange} setActiveIndex={setActiveIndex} activeIndex={activeIndex} />}
            {isRegulator && <RegulatorRoutes />}
            {isGoverningBody && <GoverningBodyRoutes />}
        </Router>
    );


}

export default Navigation;