import React from "react";
import * as PR from "../prime-react/index";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProfileMenuBar = () => {
    const navigate = useNavigate();
    const role = useSelector(state => state.auth.role);
    const pathname = window.location.pathname;
    const navigatePath = (selectedPath) => {
        pathname !== selectedPath && navigate(selectedPath)
    }
    const verticalMenu = [
        {
            id: 'profile',
            label: 'Profile Details',
            className: (pathname === '/profile-details') ? 'p-menuitem-active' : '',
            command: () => navigatePath('/profile-details')
        },
        (role !== 'analyst')&& {
            id: 'sports',
            label: 'Sports',
            className: (pathname === '/sports') ? 'p-menuitem-active' : '',
            command: () => navigatePath('/sports')
        },
    ];

    return (
        <>
           <PR.Menubar model={verticalMenu} />
        </>
    );
};

export default ProfileMenuBar;