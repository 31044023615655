import { Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./commonRoute";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import AlertDetails from "../shared/AlertDetails/AlertDetails";
import AlertsTemplate from "../modules/Analyst/Home/AlertsTemplate";
import CreateAlert from "../shared/CreateAlert/CreateAlert";
import CreateNews from "../modules/Analyst/NewsAndUpdates/GeneralNewsDetails/CreateNews";
import NewsCategories from "../modules/Analyst/NewsAndUpdates/NewsCategories/NewsCategories";
import GeneralNewsDetails from "../modules/Analyst/NewsAndUpdates/GeneralNewsDetails/GeneralNewsDetails";
import PreviewNews from "../modules/Analyst/NewsAndUpdates/GeneralNewsDetails/PreviewNews";
import EditDetails from "../modules/Analyst/NewsAndUpdates/GeneralNewsDetails/EditDetails";
import NewsAndUpdates from "../modules/Analyst/NewsAndUpdates/NewsAndUpdates";
import CaseUpdates from "../shared/CaseUpdates/CaseUpdates";
import AnalystAnalytics from "../modules/Analyst/MyAnalytics/Analytics";
import AnalystAnalyticsDetails from "../modules/Analyst/MyAnalytics/AnalyticsDetails";
import AlertTags from "../modules/Analyst/AlertTags/AlertTags";
import UserManagement from "../modules/Analyst/UserManagement/UserManagement";
import AllUsers from "../modules/Analyst/UserManagement/AllUsers/AllUsers";
import Reports from "../shared/Reports/Reports";
import CreateReport from "../modules/Analyst/Reports/CreateReport/CreateReport";
import AnalystHome from "../modules/Analyst/Home/Home";
import ReportDetail from "../shared/Reports/ReportDetail";
import PreviewReport from "../modules/Analyst/Reports/PreviewReport/PreviewReport";
import DownloadReport from "../modules/Analyst/Reports/DownloadReport/DownloadReport";
import EditReport from "../modules/Analyst/Reports/Edit Reports/EditReports";
import Profile from "../shared/Profile/Profile";
import Help from "../pages/Help/Help";
import { useMemo } from "react";
import { getValidRouteName } from "../utils/reuse";

const AnalystRoutes = () => {
     // Memoize the routeName to compute it only once on render
     const routeName = useMemo(() => {
        const lastVisitedPath = localStorage.getItem("lastVisitedPath");
        const routePaths = ['/home', '/alert-details', '/open-alerts', '/evaluating-alerts', '/suspicious-alerts', '/my-alerts', '/create-alert', '/create-news', '/news-categories', '/news-details', '/preview-news', '/edit-details', '/news', '/case-updates', '/my-analytics', '/my-analytics-details', '/tags', '/alerts', '/user-management', '/all-users', '/reports', '/create-report', '/report-detail', '/preview-report', '/download-report', '/edit-report', '/profile-details', '/help'];
        if (lastVisitedPath) {
            const getPathName = getValidRouteName(lastVisitedPath);
            const pathname = new URL(getPathName, window.location.origin).pathname;
            const basePath = pathname.split('/').slice(0, 2).join('/');
            if (routePaths.includes(basePath)) {
                localStorage.removeItem("lastVisitedPath");
                return getPathName;
            }
        }
        return '/home';
    }, []);
    return <Routes>
        <Route element={<PrivateRoute />}>
            <Route path='/' element={<Navigate to='/login' />} />
            <Route path='/login' element={ <Navigate to={`${routeName}`} /> } />
            <Route path='/otp' element={ <Navigate to={`${routeName}`}/> } />
            <Route path='/home' element={<AnalystHome />} />
            <Route path='/alert-details/:id' element={<AlertDetails />} />
            <Route path='/open-alerts' element={<AlertsTemplate alertStatus="open" title="Open Alerts" />} />
            <Route path='/evaluating-alerts' element={<AlertsTemplate alertStatus="evaluating" title="Evaluating Alerts" />} />
            <Route path='/suspicious-alerts' element={<AlertsTemplate alertStatus="" title="Suspicious Alerts" />} />
            <Route path='/my-alerts' element={<AlertsTemplate alertStatus="" title="My Alerts" />} />
            <Route path='/create-alert' element={<CreateAlert />} />
            <Route path='/create-news' element={<CreateNews />} />
            <Route path='/news-categories' element={<NewsCategories />} />
            <Route path="/news-details" element={<GeneralNewsDetails />} />
            <Route path="/preview-news" element={<PreviewNews />} />
            <Route path="/edit-details" element={<EditDetails />} />
            <Route path='/news' element={<NewsAndUpdates />} />
            <Route path='/case-updates' element={<CaseUpdates />} />
            <Route path='/my-analytics' element={<AnalystAnalytics />} />
            <Route path='/my-analytics-details' element={<AnalystAnalyticsDetails />} />
            <Route path='/tags' element={<AlertTags />} />
            <Route path='/alerts' element={<AlertsTemplate alertStatus="" />}/>
            <Route path='/user-management' element={<UserManagement />} />
            <Route path='/all-users' element={<AllUsers />} />
            <Route path='/reports' element={<Reports />} />
            <Route path='/create-report' element={<CreateReport />} />
            <Route path='/report-detail' element={<ReportDetail />} />
            <Route path='/preview-report' element={<PreviewReport />} />
            <Route path='/download-report' element={<DownloadReport />} />
            <Route path='/edit-report' element={<EditReport />} />
            <Route path='/profile-details' element={<Profile />} />
            <Route path='/help' element={<Help />} />
            <Route path='/page-not-found' element={<PageNotFound />} />
            <Route path='*' element={<Navigate to='/page-not-found' />} />
        </Route>
    </Routes>;

}

export default AnalystRoutes;