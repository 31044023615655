import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { headerMenuActions } from "../store/Header";

const Layout = ({ backBtn, backBtnLabel, children }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(headerMenuActions.setBackMenu({ backBtn: backBtn ? backBtn : '' , backBtnLabel }))
    }, [backBtn, backBtnLabel, dispatch])
    return <>{children}</>
}

export default Layout;