import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import "../CreateReport/CreateReport.scss";
import { createReport, editReport } from "../../../../services/api";
import { DateFormat, formatTime } from "../../../../utils/reuse";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, useToast } from "../../../../context/ToastContext";
import { isEmpty } from "lodash";
import { getImageForReport } from "../../../../utils/reportImages";

const editCreateReportError = 'edit_create_report_error'

const PreviewReport = () => {
  const { showToast, clearError } = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const sessionData = useSelector((state) => state.auth.sessionData);
  const [loading, setLoading] = useState(false);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
      "Content-Type": "multipart/form-data",
    };
  }, [sessionData.sessionId]);

  const reportData = state?.reportData;
  const reportFiles = state?.uploadAttachements;
  const sport = state?.sportName;
  useEffect(() => {
    if(isEmpty(reportData)){
      navigate('/reports')
    }
  }, [navigate, reportData])
  

  const handlePublish = () => {
    setLoading(true);
    clearError(editCreateReportError)
    const formData = new FormData();
    formData.append("data", JSON.stringify(reportData));
    reportFiles.forEach((file) => {
      formData.append("attachments", file);
    });

    if (reportData?.reportID) {
      editReport(formData, headers, dispatch, (response) => {
        setLoading(false);
        if (response.result === "SUCCESS") {
          showToast("success", "Report Updated Successfully");
          navigate("/reports");
        } else {
          const error = response.error;
          showToast(error.severity, error.errorMsg || error.summary, editCreateReportError);
        }
      });
    } else {
      createReport(formData, headers, dispatch, (response) => {
        setLoading(false);
        if (response.result === "SUCCESS") {
          showToast("success", "Report Created Successfully");
          navigate("/reports");
        } else {
          const error = response.error;
          showToast(error.severity, error.errorMsg || error.summary, editCreateReportError);
        }
      });
    }
  };

  const downloadAttachment = (fileData, fileName) => {
    const link = document.createElement("a");
    link.href = fileData;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const goBack = () => {
    if(state?.queryParams?.id){
      navigate(`/edit-report?id=${state?.queryParams?.id}`, {state: {...state}})
    } else {
      navigate('/create-report', {state: {formvalues: state?.formvalues}});
    }
  }

  return (
    <>
      <Layout backBtn={"reports"} backBtnLabel="Reports">
        <section className="news-tab news-details-section general-news-section">
          <div className="flex align-items-center justify-content-between mb-4">
            <div className="left">
              <h1>{reportData?.title}</h1>
            </div>
            <div className="right hide-on-download">
              <PR.Button
                label="Publish"
                className="action-buttons save-button"
                disabled={loading}
                onClick={handlePublish}
              />
            </div>
          </div>
          <div className="grid" id="pdfContent">
            <div className="col-12">
              <div className="new-list-section">
                <div className="list-wrapper">
                  <div className="news-list preview-report">
                    <div className="relative">
                      <PR.Image
                        src={getImageForReport(reportData)}
                        alt="Weekly Integrity Preview"
                        className="report-img"
                      />
                      <div className="overlay">
                        <h2>{reportData?.title}</h2>
                      </div>
                    </div>

                    <div className="attachments flex align-items-center justify-content-between mt-3">
                      <div className="flex align-items-center">
                        {reportFiles?.map((file, index) => (
                          <PR.Button
                            key={index}
                            icon="pi pi-book"
                            label={file?.name}
                            className="download-button hide-on-download"
                            onClick={() =>
                              downloadAttachment(file.data, file.name)
                            }
                          />
                        ))}
                      </div>
                      {/* <DownloadPDF
                        contentId="pdfContent"
                        fileName="report.pdf"
                        buttonLabel="Download PDF"
                        buttonClassName="save-button hide-on-download"
                        headerText="Report"
                      /> */}
                    </div>

                    <div className="col-12 p-0 mt-3">
                      <div className="content">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: reportData?.description,
                          }}
                        />
                      </div>
                      <div className="attachments flex gap-3 mt-3">
                        {reportData?.reportType && (
                          <span className="type-info">
                            Report Type: {reportData?.reportType}
                          </span>
                        )}
                        {sport && (
                          <span className="type-info">Sport: {sport}</span>
                        )}
                      </div>
                      <div className="card mt-5">
                        <ul className="list-none gap-4 flex align-items-center justify-content-between mb-0">
                          <li>
                            <i className="pi pi-user"></i>{" "}
                            <span className="author">
                              {sessionData?.name}
                            </span>
                          </li>
                          <li>
                            <i className="pi pi-calendar"></i>{" "}
                            <span>{DateFormat(new Date())} {formatTime(new Date())}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <ErrorMessage id={editCreateReportError} />
                <div className="text-right mt-4 hide-on-download">
                  <PR.Button label="Go back" className="action-buttons mr-2"   disabled={loading} onClick={goBack} />
                  <PR.Button
                    label="Publish"
                    className="action-buttons save-button"
                    disabled={loading}
                    onClick={handlePublish}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default PreviewReport;
