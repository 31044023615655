import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as PR from "../../prime-react/index";
import {
  getGroups,
  getPlayers,
  getSports,
  getLocations,
  getTournament,
  getGroupsbyRole,
  getTournamentLevels,
} from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { formatShortDate, getRoleId, queryLimit } from "../../utils/reuse";
import { useLocation } from "react-router-dom";
const SearchFilter = ({
  alertsActiveIndex,
  setFilters,
  staticOptionData,
  setFilterDialogVisible,
  selectedFilters
}) => {
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const location = useLocation();
  const [checkedReportTypes, setCheckedReportTypes] = useState({});
  const [checkedReceivers, setCheckedReceivers] = useState({});
  const [receivers, setReceivers] = useState([]);
  const [filteredReceivers, setFilteredReceivers] = useState([]);
  const sessionData = useSelector((state) => state.auth.sessionData);
  const role = sessionData?.role;
  const [error, setError] = useState(null)
  const [disableTournamentLevel, setDisableTournamentLevel] = useState(true);
  const [keywords, setKeywords] = useState(selectedFilters?.keyword??'');

    /** Sports */
    const [sports, setSports] = useState([]);
    const [checkedSports, setCheckedSports] = useState({});
    const [sportIds, setSportIds] = useState([]);

  /** Date */
  const [date, setDate] = useState([new Date(), new Date()]);
  const [dateRange, setDateRange] = useState("");


  const headers = useMemo(() => {
    return { sessionid: sessionData.sessionId };
  }, [sessionData.sessionId]);

  useEffect(() => {
    const selectedSportIds = selectedFilters?.sports?.map((sport) => sport.id);
    setSportIds(selectedSportIds);
    selectedFilters?.combinedStatus?.forEach(({ id }) => {
      setCheckedStatus((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.tags?.forEach(({ id }) => {
      setCheckedPlayers((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });
    selectedFilters?.sports?.forEach(({ id }) => {
      setCheckedSports((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.tournamentLevels?.forEach(({ id }) => {
      setCheckedTournamentLevels((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.alertType?.forEach(({ id }) => {
      setCheckedAlerts((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.company?.forEach(({ id }) => {
      setCheckedCompanies((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.responseType?.forEach(({ id }) => {
      setCheckedResponses((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });
    if(selectedFilters?.date?.date?.length){
      setDate(selectedFilters?.date?.date);
    }
    if(selectedFilters?.date?.dateRange){
      setDateRange(selectedFilters?.date?.dateRange);
    }

    selectedFilters?.gender?.forEach(({ id }) => {
      setCheckedGenders((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.tournament?.forEach(({ id }) => {
      setCheckedTournaments((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.location?.forEach(({ id }) => {
      setCheckedLocations((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });
    selectedFilters?.channel?.forEach(({ id }) => {
      setCheckedChannel((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.timeOfBet?.forEach(({ id }) => {
      setCheckedTimeOfBet((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.stateOfBet?.forEach(({ id }) => {
      setCheckedStateOfBet((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.timeOfAlert?.forEach(({ id }) => {
      setCheckedAlertsRaised((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    if (selectedFilters?.maxBets?.length > 0) {
      setCheckedMaxBet(selectedFilters?.maxBets[0].id);
    }

    selectedFilters?.reportType?.forEach(({ id }) => {
      setCheckedReportTypes((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });

    selectedFilters?.receiver?.forEach(({ id }) => {
      setCheckedReceivers((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    });
  }, [selectedFilters]);

  const getActiveIndex = () => {
    if (role === "analyst" && (pathname === "/reports")) {
      // reports search-tabs start with sports(2)
      return 3
    }
    // default search-tabs start with 0
    return 0
  }

  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(getActiveIndex());

  const removeHandler = (prevState, data) => {
    const updatedCheckedItems = { ...prevState };
    delete updatedCheckedItems[data.id];
    return updatedCheckedItems;
  };

  /** Report Type */
  const reportTypeCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedReportTypes((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected report type values
  const getSelectedReportTypes = () => {
    return staticOptionData?.reportTypeFilter?.filter(
      (item) => checkedReportTypes[item.id]
    );
  };

  // Removing value from the filtered report type list
  const handleRemoveReportTypeFilter = (reportType) => {
    setCheckedReportTypes((prevState) => {
      return removeHandler(prevState, reportType);
    });
  };
  /** End Report Type */

  /** Receiver */
  const receiverCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedReceivers((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected receiver values
  const getSelectedReceivers = () => {
    return receivers?.filter((item) => checkedReceivers[item.id]);
  };

  // Removing value from the filtered receiver list
  const handleRemoveReceiverFilter = (receiver) => {
    setCheckedReceivers((prevState) => {
      return removeHandler(prevState, receiver);
    });
  };
  /** End Receiver */

  /** Status */
  const getStatusOptions = () => {
    if ((role === "analyst" && pathname === "/suspicious-alerts") ||
      // in allalerts screen when navigated to Suspicious alerts tab : alertsActiveIndex === 1
      ((role === "member" || role === "managedmember") && pathname === "/alerts" && alertsActiveIndex === 1)) {
      return staticOptionData.combinedStatus.filter((status) => {
        return (status.id.includes("underinvestigation") || status.id.includes("closed") || status.id.includes("sanctioned"))
      })
      // in allalerts screen when navigated to Required-action tab : alertsActiveIndex === 2
    } else if (role === "member" && pathname === "/alerts" && alertsActiveIndex === 2) {
      return staticOptionData.combinedStatus.filter((status) => {
        return !(status.id.includes("-"))
      })
    } else {
      return staticOptionData.combinedStatus
    }
  }
  const [status] = useState(getStatusOptions());
  const [checkedStatus, setCheckedStatus] = useState({});

  // On status values checked
  const handleStatusChange = (e) => {
    const { value, checked } = e.target;
    setCheckedStatus((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected status values
  const getSelectedStatus = () => {
    return status?.filter((item) => checkedStatus[item.id]);
  };

  // Removing value from the filtered status list
  const handleStatusRemove = (status) => {
    setCheckedStatus((prevState) => {
      return removeHandler(prevState, status);
    });
  };
  /** End Status */

  /** Player/ Team */
  const [playerNames, setPlayerNames] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [checkedPlayers, setCheckedPlayers] = useState({});

  // Get list of all the Players
  const getPlayerOrTeam = useCallback(() => {
    setLoading(true);
    getPlayers('', headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const Players = responseData;
        const result = Players?.length > 0 ? Players?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setPlayerNames(result);
        setFilteredPlayers(result);
      } else {
        setPlayerNames([]);
      }
      setLoading(false);
    });
  }, [dispatch, headers]);

  // Search handler to filter the response values
  const searchPlayersHandler = (event) => {
    const searchedValue = event.target.value;
    const filteredData =
      playerNames &&
      playerNames?.filter((item) =>
        item.name.toLowerCase().includes(searchedValue.toLowerCase())
      );
    setFilteredPlayers(filteredData);
  };

  // Checkbox handler to list all the selected values
  const playerCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedPlayers((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected status values
  const getSelectedPlayers = () => {
    return playerNames.filter((item) => checkedPlayers[item.id]);
  };

  // Removing value from the filtered Player list
  const handleRemovePlayerFilter = (playerName) => {
    setCheckedPlayers((prevState) => {
      return removeHandler(prevState, playerName);
    });
  };

  /** End Player/ Team */

  /** Sports */


  const getSportsHandler = useCallback(() => {
    setLoading(true);
    getSports(alertsActiveIndex, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const sports = responseData;
        const result = sports?.length > 0 ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setSports(result);
      } else {
        setSports([]);
      }
      setLoading(false);
    });
  }, [alertsActiveIndex, dispatch, headers]);

  // Checkbox handler to list all the selected values
  const sportFilterHandler = ({ id, checked }) => {
    const selectedSportIds = sports
      .filter((item) => {
        if (id === item.id) {
          return checked;
        }
        return checkedSports[item.id];
      })
      .map((item) => item.id);
    setSportIds(selectedSportIds);
    const sportNames = sports?.filter(s => selectedSportIds.includes(s.id)).map(s => s.name);
    if (!sportNames?.includes('Tennis')) {
      setCheckedTournamentLevels({})
      setFilteredTournamentLevels([]);
    }
  }
  // Listing all the selected sport values
  const getSelectedSports = () => {
    return sports.filter((item) => checkedSports[item.id]);
  };
  const sportCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedSports((prevState) => {
      return {
        ...prevState,
        [value]: checked,
      };
    });
    sportFilterHandler({ id: value, checked })
  };

  // Removing value from the filtered sport list
  const handleRemoveSportFilter = (sport) => {
    setCheckedSports((prevState) => {
      return removeHandler(prevState, sport);
    });
    sportFilterHandler({ id: sport.id, checked: false })
  };
  /** End Sports Team */

    /** Tournment Levels */
    const [tournamentLevelsList, setTournamentLevelsList] = useState([]);
    const [filteredTournamentLevels, setFilteredTournamentLevels] = useState([]);
    const [checkedTournamentLevels, setCheckedTournamentLevels] = useState({});
  
    // Get list of all the tournaments
    const getTournamentLevelsHandler = useCallback(() => {
      getTournamentLevels(alertsActiveIndex, headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const tournamentLevels = responseData;
          const result = tournamentLevels?.length > 0 ? tournamentLevels?.sort((a, b) => a.name.localeCompare(b.name)) : [];
          setTournamentLevelsList(result);
          setFilteredTournamentLevels(result);
        } else {
          setTournamentLevelsList([]);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, headers]);
  
    // Search handler to filter the response values
    const searchTournamentLevelsHandler = (event) => {
      const searchedValue = event.target.value;
      const filteredData =
        tournamentLevelsList &&
        tournamentLevelsList?.filter((item) =>
          item.name.toLowerCase().includes(searchedValue.toLowerCase())
        );
      setFilteredTournamentLevels(filteredData);
    };
  
    // Checkbox handler to list all the selected values
    const tournamentLevelsCheckboxHandler = (e) => {
      const { value, checked } = e.target;
      setCheckedTournamentLevels((prevState) => ({
        ...prevState,
        [value]: checked,
      }));
    };
  
    // Listing all the selected Tournament values
    const getSelectedTournamentLevels = () => {
      return tournamentLevelsList?.filter((item) => checkedTournamentLevels[item.id]);
    };
  
    // Removing value from the filtered Tournament list
    const handleRemoveTournamentLevelsFilter = (response) => {
      setCheckedTournamentLevels((prevState) => {
        return removeHandler(prevState, response);
      });
    };
    /** End Tournment Levels */

  /** Alert Type */
  const [alertTypes] = useState(staticOptionData?.alertTypeFilter);
  const [checkedAlerts, setCheckedAlerts] = useState({});

  // Checkbox handler to list all the selected values
  const alertCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedAlerts((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected alert values
  const getSelectedAlerts = () => {
    return alertTypes?.filter((item) => checkedAlerts[item.id]);
  };

  // Removing value from the filtered alert list
  const handleRemoveAlertFilter = (alert) => {
    setCheckedAlerts((prevState) => {
      return removeHandler(prevState, alert);
    });
  };
  /** End Alert Type */

  /** Response Type */
  const [responseTypes] = useState(staticOptionData?.responseTypeFilter?.filter(type => type.id !== 'all'));
  const [checkedResponses, setCheckedResponses] = useState({});

  // Checkbox handler to list all the selected values
  const responseCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedResponses((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected response values
  const getSelectedResponses = () => {
    return responseTypes?.filter((item) => checkedResponses[item.id]);
  };

  // Removing value from the filtered response list
  const handleRemoveResponseFilter = (response) => {
    setCheckedResponses((prevState) => {
      return removeHandler(prevState, response);
    });
  };
  /** End Response Type */

  const roleId = getRoleId(sessionData.role);

  /** Company Tab */
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [checkedCompanies, setCheckedCompanies] = useState({});
  // Get list of all the members
  const getCompany = useCallback(() => {
    setLoading(true);
    getGroups(roleId, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const members = responseData;
        const result = members?.length > 0 ? members?.sort((a, b) => a.name.localeCompare(b.name))  : [];
        setCompanies(result);
        setFilteredCompanies(result);
      } else {
        setCompanies([]);
      }
      setLoading(false);
    });
  }, [roleId, headers, dispatch]);

  // Search handler to filter the response values
  const searchCompaniesHandler = (event) => {
    const searchedValue = event.target.value;
    const filteredData =
      companies &&
      companies?.filter((item) =>
        item.name.toLowerCase().includes(searchedValue.toLowerCase())
      );
    setFilteredCompanies(filteredData);
  };


  // Checkbox handler to list all the selected values
  const companyCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedCompanies((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected response values
  const getSelectedCompanies = () => {
    return companies?.filter((item) => checkedCompanies[item.id]);
  };

  // Removing value from the filtered response list
  const handleRemoveCompanyFilter = (response) => {
    setCheckedCompanies((prevState) => {
      return removeHandler(prevState, response);
    });
  };
  /** End Company */


  const handleDateRange = (e) => {
    setDate(e.value);

    if (e.value && e.value[0] !== null && e.value[1] === null) {
      let [startDate, endDate] = [e.value[0], e.value[0]]
      setDateRange(`${formatShortDate(startDate)} - ${formatShortDate(endDate)}`)
    } else if (e.value && e.value[0] !== null && e.value[1] !== null) {
      let [startDate, endDate] = e.value;
      setDateRange(
        `${formatShortDate(startDate)} - ${formatShortDate(endDate)}`
      );
    }

  };

  // Removing Date
  const handleRemoveDateFilter = () => {
    setDateRange("");
    setDate([new Date(), new Date()]);
  };

  /** End Date */

  /** Tournament */
  const [tournamentsList, setTournamentList] = useState([]);
  const [filteredTournaments, setFilteredTournaments] = useState([]);
  const [checkedTournaments, setCheckedTournaments] = useState({});

  // Get list of all the tournaments
  const getTournamenthandler = useCallback(() => {
    const obj = {
      sportid: sportIds.join(","),
    };
    getTournament(obj, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const tournaments = responseData;
        const result = tournaments?.length > 0 ? tournaments?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setTournamentList(result);
        setFilteredTournaments(result);
      } else {
        setTournamentList([]);
      }
    });
  }, [dispatch, headers, sportIds]);

  // Search handler to filter the response values
  const searchTournamentHandler = (event) => {
    const searchedValue = event.target.value;
    const filteredData =
      tournamentsList &&
      tournamentsList?.filter((item) =>
        item.name.toLowerCase().includes(searchedValue.toLowerCase())
      );
    setFilteredTournaments(filteredData);
  };

  // Checkbox handler to list all the selected values
  const tournamentCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedTournaments((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected Tournament values
  const getSelectedTournaments = () => {
    return tournamentsList?.filter((item) => checkedTournaments[item.id]);
  };

  // Removing value from the filtered Tournament list
  const handleRemoveTournamentFilter = (response) => {
    setCheckedTournaments((prevState) => {
      return removeHandler(prevState, response);
    });
  };
  /** End Tournment */

  /** Gender */
  const [gender] = useState(staticOptionData?.gender);
  const [checkedGenders, setCheckedGenders] = useState({});

  // Checkbox handler to list all the selected values
  const genderCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedGenders((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected gender values
  const getSelectedGenders = () => {
    return gender?.filter((item) => checkedGenders[item.id]);
  };

  // Removing value from the filtered gender list
  const handleRemoveGenderFilter = (gender) => {
    setCheckedGenders((prevState) => {
      return removeHandler(prevState, gender);
    });
  };
  /** End Gender */

  /** Location */
  const [locations, setLocations] = useState([]);
  const [searchSports, setSearchSports] = useState('');
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [checkedLocations, setCheckedLocations] = useState({});

  // Get list of all the locations
  const getLocationHandler = useCallback(() => {
    setLoading(true);
    getLocations(headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const locations = responseData;
        const result = locations?.length > 0 ? locations?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setLocations(result);
        setFilteredLocations(result);
      } else {
        setLocations([]);
      }
      setLoading(false);
    });
  }, [dispatch, headers]);

  // Search handler to filter the response values
  const searchLocationsHandler = (event) => {
    const searchedValue = event.target.value;
    const filteredData =
      locations &&
      locations?.filter((item) =>
        item.name.toLowerCase().includes(searchedValue.toLowerCase())
      );
    setFilteredLocations(filteredData);
  };

  // Checkbox handler to list all the selected values
  const locationCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedLocations((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected response values
  const getSelectedLocations = () => {
    return locations?.filter((item) => checkedLocations[item.id]);
  };

  // Removing value from the filtered response list
  const handleRemoveLocationFilter = (response) => {
    setCheckedLocations((prevState) => {
      return removeHandler(prevState, response);
    });
  };

  /** End Location */

  /** Channel */
  const [channel] = useState(staticOptionData?.channels);
  const [checkedChannel, setCheckedChannel] = useState({});

  // Checkbox handler to list all the selected values
  const channelCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedChannel((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected channel values
  const getSelectedChannels = () => {
    return channel?.filter((item) => checkedChannel[item.id]);
  };

  // Removing value from the filtered channel list
  const handleRemoveChannelFilter = (channel) => {
    setCheckedChannel((prevState) => {
      return removeHandler(prevState, channel);
    });
  };
  /** End Channel */

  /** Time of Bet */
  const [timeOfBet] = useState(staticOptionData?.timeOfBet);
  const [checkedTimeOfBet, setCheckedTimeOfBet] = useState({});

  // Checkbox handler to list all the selected values
  const timeOfBetCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedTimeOfBet((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected timeOfBet values
  const getSelectedTimeOfBet = () => {
    return timeOfBet?.filter((item) => checkedTimeOfBet[item.id]);
  };

  // Removing value from the filtered timeOfBet list
  const handleRemoveTimeOfBetFilter = (timeOfBet) => {
    setCheckedTimeOfBet((prevState) => {
      return removeHandler(prevState, timeOfBet);
    });
  };
  /** End Time of Bet */

  /** State of Bet */
  const [stateOfBet] = useState(staticOptionData?.stateOfBetsFilter);
  const [checkedStateOfBet, setCheckedStateOfBet] = useState({});

  // Checkbox handler to list all the selected values
  const stateOfBetCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedStateOfBet((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected State of Bet values
  const getSelectedStateOfBets = () => {
    return stateOfBet?.filter((item) => checkedStateOfBet[item.id]);
  };

  // Removing value from the filtered State of Bet list
  const handleRemoveStateOfBetFilter = (stateOfBet) => {
    setCheckedStateOfBet((prevState) => {
      return removeHandler(prevState, stateOfBet);
    });
  };
  /** End State of Bet */

  /** Max Bet */
  const [maxBet] = useState(staticOptionData?.maxBet);
  const [checkedMaxBet, setCheckedMaxBet] = useState("");

  // Checkbox handler to list all the selected values
  const maxBetRadioGroupHandler = (value) => {
    setCheckedMaxBet(value);
  };

  // Listing all the selected Max Bet values
  const getSelectedMaxBets = () => {
    return maxBet?.filter((item) => item.id === checkedMaxBet);
  };

  // Removing value from the filtered list
  const handleRemoveMaxBetFilter = (maxBet) => {
    setCheckedMaxBet("");
  };

  /** End Max Bet */

  /** Alert raised */
  const [alertsRaised] = useState(staticOptionData?.alertsRaised);
  const [checkedAlertsRaised, setCheckedAlertsRaised] = useState({});

  // Checkbox handler to list all the selected values
  const alertsRaisedCheckboxHandler = (e) => {
    const { value, checked } = e.target;
    setCheckedAlertsRaised((prevState) => ({
      ...prevState,
      [value]: checked,
    }));
  };

  // Listing all the selected Alert Raised values
  const getSelectedAlertsRaised = () => {
    return alertsRaised?.filter((item) => checkedAlertsRaised[item.id]);
  };

  // Removing value from the filtered list
  const handleRemoveAlertsRaised = (alertsRaised) => {
    setCheckedAlertsRaised((prevState) => {
      return removeHandler(prevState, alertsRaised);
    });
  };
  /** End Alert raised */

  // CombinedGroupbyroleId
  const GOVERNING_BODIES_ROLE = 2; // governing bodies
  const REGULATORS_ROLE = 3; // regulators

  const getGroup = useCallback(() => {
    setLoading(true);
    getGroupsbyRole(GOVERNING_BODIES_ROLE, REGULATORS_ROLE, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const members = responseData;
        const result = members?.length > 0 ? members?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setReceivers(result);
        setFilteredReceivers(result);
      } else {
        setFilteredReceivers([]);
      }
      setLoading(false);
    });
  }, [dispatch, headers]);

  const formattedDate = () => {
    if (date === undefined || date.length === 0) {
      return []
    } else if (date[1] === null && date[0] !== null) {
      return [date[0], date[0]]
    } else if (date[1] !== null && date[0] !== null) {
      return date
    }
    return [];
  }

  const getAlertStatus = () => {
    const alertStatuses = getSelectedStatus().map(item => {
      return item.id.split('-')[0]
    })
    const statusSet = [...new Set(alertStatuses)]
    const statusFiltered = statusSet.filter(item => item !== "")

    return statusFiltered.map(item => {
      return { id: item }
    })
  }

  const getCaseStatus = () => {

    const caseStatuses = getSelectedStatus().map(item => {
      return item.id.split('-')[1]
    })

    const statusSet = [...new Set(caseStatuses)]
    const statusFiltered = statusSet.filter(element => element !== undefined);

    return statusFiltered.map(item => {
      return { id: item }
    })
  }
  const [locationError,setLocationError] = useState('');
  const applyFilters = () => {

    const restrictedLocation = getSelectedLocations().find(location =>{
      return location.name === "No Country"
    })

    const restrictedSport = getSelectedSports().find(sport => {
      return sport.name === "eSports"
    })
    if(getSelectedSports().length>0){
      if (restrictedLocation && !restrictedSport) {

        setLocationError('Invalid Search')
        setTimeout(() => {
          setLocationError('')
        },2000)
        return;
      }
    }
    const global = {
      combinedStatus: getSelectedStatus(),
      alertStatus: getAlertStatus(),
      caseStatus: getCaseStatus(),
      tags: getSelectedPlayers(),
      sports: getSelectedSports(),
      tournamentLevels: getSelectedTournamentLevels(),
      alertType: getSelectedAlerts(),
      responseType: getSelectedResponses(),
      company: getSelectedCompanies(),
      date: { date: dateRange ? formattedDate() : '', dateRange },
      tournament: getSelectedTournaments(),
      gender: getSelectedGenders(),
      location: getSelectedLocations(),
      reportType: getSelectedReportTypes(),
      receiver: getSelectedReceivers(),
      channel: getSelectedChannels(),
      timeOfBet: getSelectedTimeOfBet(),
      stateOfBet: getSelectedStateOfBets(),
      maxBets: getSelectedMaxBets(),
      timeOfAlert: getSelectedAlertsRaised(),
      keyword: keywords 
    };
    setFilters(global);
    setFilterDialogVisible(false);
  };

  const othersTabFiltersSelected = () => {
    const filters = [
      getSelectedChannels(),
      getSelectedTimeOfBet(),
      getSelectedStateOfBets(),
      getSelectedMaxBets(),
      getSelectedAlertsRaised(),
    ];
    return filters?.some((filter) => filter?.length > 0);
  };

  const tabChangeHandler = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    getSportsHandler();

    if (location.pathname === "/reports") {
      getGroup();
    }

    if (location.pathname !== "/reports") {
      if (!isCompanyTabDisabled) {
        getCompany();
      }
      getLocationHandler();
    }

    if (isPlayerVisible()) {
      getPlayerOrTeam();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    const sportData = sportIds?.length ? sports.filter(s => sportIds?.includes(s.id)) : [];
    const sportNames = sportData.map(s => s.name);
    if (location.pathname !== "/reports") {
      if (sportNames.includes('Tennis')) {
        setDisableTournamentLevel(false);
        getTournamentLevelsHandler();
      } else if(sports.length){
        setDisableTournamentLevel(true);
        setTournamentLevelsList([]);
        setCheckedTournamentLevels({})
      }
    }
  }, [getTournamentLevelsHandler, location.pathname, sportIds, sports]);

  useEffect(() => {
    if (location.pathname !== "/reports") {
      if (sportIds?.length > 0) {
        getTournamenthandler();
      } else {
        setTournamentList([])
      }
    }
    // eslint-disable-next-line
  }, [sportIds]);


  const isStatusTabDisabled = (
    role === "analyst" &&
    (pathname === "/open-alerts" || pathname === "/evaluating-alerts")
  )
  //company search disabled for my-alerts
  const isCompanyTabDisabled = ((role === "member" || role === "managedmember")&& pathname === "/alerts" && alertsActiveIndex === 3)

  const resetFilters = () => {
    setCheckedStatus({})
    setCheckedPlayers({})
    setCheckedSports({})
    setCheckedTournamentLevels({})
    setCheckedAlerts({})
    setCheckedResponses({})
    setCheckedCompanies({})
    setDateRange("")
    setDate([new Date(), new Date()]);  
    setCheckedTournaments({})
    setCheckedGenders({})
    setCheckedLocations({})
    setCheckedReportTypes({})
    setCheckedReceivers({})
    setCheckedChannel({})
    setCheckedTimeOfBet({})
    setCheckedStateOfBet({})
    setCheckedMaxBet('')
    setCheckedAlertsRaised({})
    setKeywords('')
    setDisableTournamentLevel(true);
  }
  const buttonSection = () => {
    return (
      <div className="flex flex-wrap justify-content-end gap-3 border-top">

        <PR.Button
          label="Reset Filters"
          outlined
          autoFocus
          className="logout-btns reset-btn"
          onClick={resetFilters}
        />
        <PR.Button
          label="Cancel"
          onClick={() => setFilterDialogVisible(false)}
          outlined
          className="logout-btns"
        />
        <PR.Button
          label="Apply Filters"
          outlined
          autoFocus
          className={role === 'analyst' ? 'logout-btns click-btn color-update' : 'logout-btns click-btn'}
          onClick={applyFilters}
        />
      </div>
    )
  }

  const isPlayerVisible = () => {
    if (role === "analyst") {
      if ((location.pathname === "/reports") || (location.pathname === "/alerts")) {
        return false
      }
    }
    return true
  }

  return (
    <>
      <div className="filter-dialog-body-section">
        <div className="grid">
          <div className="col-9">
            <PR.TabView
              activeIndex={activeIndex}
              onTabChange={(e) => tabChangeHandler(e.index)}
            >
              {location.pathname !== "/reports" && (<PR.TabPanel header="Keyword">
                <div className="flex filter-options-section">
                  <div className="filters-list">
                    <div className=" mb-4">
                      <div className="flex align-items-center w-12">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="keywordSearch"
                            placeholder="Enter Keyword"
                            className="search-box"
                            value={keywords}
                            onChange={({target:{value}}) => {
                              if(value.length <= queryLimit){
                                setKeywords(value)
                              } else {
                                setError(`Keyword can be at most ${queryLimit} characters long`);
                                setTimeout(() => {
                                  setError(null)
                              }, 2500)
                              }
                            }}
                          />
                        </span>
                      </div>
                      {error && <p className="error-message mt-2">{error}</p>}
                    </div>
                    <span>This will search in Event Name, Description and Advice.</span>
                  </div>
                  {buttonSection()}
                </div>
              </PR.TabPanel>)}
              {location.pathname !== "/reports" && (
                <PR.TabPanel header="Status" disabled={isStatusTabDisabled}>
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {status?.map((row, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              value={row.id}
                              inputId={row.id}
                              checked={checkedStatus[row.id]}
                              onChange={handleStatusChange}
                            ></PR.Checkbox>
                            <label
                              htmlFor={row.id}
                              className="ml-2 font-medium"
                            >
                              {row.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {isPlayerVisible() && (
                <PR.TabPanel header="Player / Team">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-4">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="playerSearch"
                            placeholder="Search"
                            className="search-box"
                            onChange={searchPlayersHandler}
                          />
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredPlayers &&
                          filteredPlayers?.map((player, index) => {
                            return (
                              <div className="flex align-items-center status-box" key={index}>
                                <PR.Checkbox
                                  inputId={player.id}
                                  value={player.id}
                                  onChange={playerCheckboxHandler}
                                  checked={checkedPlayers[player.id]}
                                ></PR.Checkbox>
                                <label htmlFor={player.id} className="ml-2 font-medium">
                                  {player.name}
                                </label>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              <PR.TabPanel header="Sports">
                <div className="flex filter-options-section">
                  <div className="filters-list">
                    <div className="flex flex-wrap gap-3">
                      {loading ? (
                        <div>
                          <i className="pi pi-spin pi-spinner"></i>
                        </div>
                      ) : (<>
                        <div className="flex align-items-center w-12 mb-2">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="sportSearch"
                            placeholder="Search"
                            className="search-box"
                            onChange={({target})=>{setSearchSports(target.value?.toLowerCase())}}
                          />
                        </span>
                      </div>
                          {
                            sports &&
                            sports?.filter(e => e.name.toLowerCase().includes(searchSports)).map((sport, index) => (
                              <div className="flex align-items-center status-box" key={index} >
                                <PR.Checkbox
                                  inputId={sport.id}
                                  value={sport.id}
                                  onChange={sportCheckboxHandler}
                                  checked={checkedSports[sport.id]}
                                ></PR.Checkbox>
                                <label htmlFor={sport.id} className="ml-2 font-medium">{sport.name}</label>
                              </div>
                            ))
                          }
                      </>
                      )}
                    </div>
                  </div>
                  {buttonSection()}
                </div>
              </PR.TabPanel>

              {location.pathname !== "/reports" && (
                <PR.TabPanel
                  header="Tournament Levels"
                  disabled={disableTournamentLevel}
                >
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-4">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="tournamentSearch"
                            placeholder="Search"
                            className="search-box"
                            onChange={searchTournamentLevelsHandler}
                          />
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredTournamentLevels &&
                          filteredTournamentLevels?.map((tournamentLevel, index) => (
                            <div
                              className="flex align-items-center status-box"
                              key={index}
                            >
                              <PR.Checkbox
                                inputId={tournamentLevel.id}
                                value={tournamentLevel.id}
                                onChange={tournamentLevelsCheckboxHandler}
                                checked={checkedTournamentLevels[tournamentLevel.id]}
                              ></PR.Checkbox>
                              <label
                                htmlFor={tournamentLevel.id}
                                className="ml-2 font-medium"
                              >
                                {tournamentLevel.name}
                              </label>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}

              {location.pathname !== "/reports" && (
                <PR.TabPanel header="Alert Type">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {alertTypes?.map((alert, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              inputId={alert.id}
                              value={alert.id}
                              onChange={alertCheckboxHandler}
                              checked={checkedAlerts[alert.id]}
                            ></PR.Checkbox>
                            <label htmlFor={alert.id} className="ml-2 font-medium">
                              {alert.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {location.pathname !== "/reports" && (
                <PR.TabPanel header="Response Type">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {responseTypes?.map((response, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              inputId={response.id}
                              value={response.id}
                              onChange={responseCheckboxHandler}
                              checked={checkedResponses[response.id]}
                            ></PR.Checkbox>
                            <label htmlFor={response.id} className="ml-2 font-medium">
                              {response.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {location.pathname !== "/reports" && (
                <PR.TabPanel header="Company" disabled={isCompanyTabDisabled}>
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-4">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="companySearch"
                            placeholder="Search"
                            className="search-box"
                            onChange={searchCompaniesHandler}
                          />
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredCompanies &&
                          filteredCompanies?.map((member, index) => {
                            return (
                              <div
                                className="flex align-items-center status-box"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={member.id}
                                  value={member.id}
                                  onChange={companyCheckboxHandler}
                                  checked={checkedCompanies[member.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={member.id}
                                  className="ml-2 font-medium"
                                >
                                  {member.name}
                                </label>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              <PR.TabPanel header="Date">
                <div className="filter-options-section">
                  <div className="card date-calendar flex justify-content-center">
                    <PR.Calendar
                      value={date}
                      inputId="searchDate"
                      onChange={handleDateRange}
                      inline
                      selectionMode="range"
                      maxDate={new Date()}
                    />
                  </div>
                  {buttonSection()}
                </div>
              </PR.TabPanel>
              {location.pathname !== "/reports" && (
                <PR.TabPanel
                  header="Tournament / League"
                  disabled={sportIds?.length === 0 || sportIds === undefined}
                >
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-4">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="leagueSearch"
                            placeholder="Search"
                            className="search-box"
                            onChange={searchTournamentHandler}
                          />
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredTournaments &&
                          filteredTournaments?.map((tournament, index) => (
                            <div
                              className="flex align-items-center status-box"
                              key={index}
                            >
                              <PR.Checkbox
                                inputId={tournament.id}
                                value={tournament.id}
                                onChange={tournamentCheckboxHandler}
                                checked={checkedTournaments[tournament.id]}
                              ></PR.Checkbox>
                              <label
                                htmlFor={tournament.id}
                                className="ml-2 font-medium"
                              >
                                {tournament.name}
                              </label>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {location.pathname !== "/reports" && (
                <PR.TabPanel header="Gender">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {gender?.map((row, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              inputId={row.id}
                              value={row.id}
                              onChange={genderCheckboxHandler}
                              checked={checkedGenders[row.id]}
                            ></PR.Checkbox>
                            <label htmlFor={row.id} className="ml-2 font-medium">
                              {row.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {location.pathname !== "/reports" && (
                <PR.TabPanel header="Location">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-4">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="locationSearch"
                            placeholder="Search"
                            className="search-box"
                            onChange={searchLocationsHandler}
                          />
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {loading ? (
                          <div>
                            <i className="pi pi-spin pi-spinner"></i>
                          </div>
                        ) : (
                          filteredLocations &&
                          filteredLocations?.map((location, index) => {
                            return (
                              <div
                                className="flex align-items-center status-box"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={location.id}
                                  value={location.id}
                                  onChange={locationCheckboxHandler}
                                  checked={checkedLocations[location.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={location.id}
                                  className="ml-2 font-medium"
                                >
                                  {location.name}
                                </label>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
              {role === "analyst" && location.pathname === "/reports" ? (
                <PR.TabPanel header="Report Type">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex flex-wrap gap-3">
                        {staticOptionData?.reportTypeFilter?.map(
                          (reportType, index) => (
                            <div
                              className="flex align-items-center status-box"
                              key={index}
                            >
                              <PR.Checkbox
                                inputId={reportType.id}
                                value={reportType.id}
                                onChange={reportTypeCheckboxHandler}
                                checked={checkedReportTypes[reportType.id]}
                              />
                              <label
                                htmlFor={reportType.id}
                                className="ml-2 font-medium"
                              >
                                {reportType.name}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              ) : null}
              {role === "analyst" && location.pathname === "/reports" && (
                <PR.TabPanel header="Receiver">
                  <div className="flex filter-options-section">
                    <div className="filters-list">
                      <div className="flex align-items-center w-12 mb-4">
                        <span className="p-input-icon-left w-12">
                          <i className="pi pi-search" />
                          <PR.InputText
                            id="receiverSearch"
                            placeholder="Search"
                            className="search-box"
                            onChange={(e) => {
                              const filtered = receivers.filter((receiver) =>
                                receiver.name
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase())
                              );
                              setFilteredReceivers(filtered);
                            }}
                          />
                        </span>
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {filteredReceivers?.map((receiver, index) => (
                          <div
                            className="flex align-items-center status-box"
                            key={index}
                          >
                            <PR.Checkbox
                              inputId={receiver.id}
                              value={receiver.id}
                              onChange={receiverCheckboxHandler}
                              checked={checkedReceivers[receiver.id]}
                            />
                            <label htmlFor="open" className="ml-2 font-medium">
                              {receiver.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}

              {location.pathname !== "/reports" && (
                <PR.TabPanel header="Others">
                  <div className="filter-options-section">
                    <div className="other-tab">
                      <div className="col-12 mb-2">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            Channel
                          </span>
                          <div className="flex flex-wrap gap-3 mt-1">
                            {channel?.map((row, index) => (
                              <div
                                className="flex align-items-center"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={row.id}
                                  value={row.id}
                                  onChange={channelCheckboxHandler}
                                  checked={checkedChannel[row.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={row.id}
                                  className="ml-2 font-medium"
                                >
                                  {row.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            Time of bet
                          </span>
                          <div className="flex flex-wrap gap-3 mt-1">
                            {timeOfBet?.map((row, index) => (
                              <div
                                className="flex align-items-center"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={`time-of-bet-${row.id}`}
                                  value={row.id}
                                  onChange={timeOfBetCheckboxHandler}
                                  checked={checkedTimeOfBet[row.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={`time-of-bet-${row.id}`}
                                  className="ml-2 font-medium"
                                >
                                  {row.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            State of the bets
                          </span>
                          <div className="flex flex-wrap gap-3 mt-1">
                            {stateOfBet?.map((row, index) => (
                              <div
                                className="flex align-items-center"
                                key={index}
                              >
                                <PR.Checkbox
                                  inputId={row.id}
                                  value={row.id}
                                  onChange={stateOfBetCheckboxHandler}
                                  checked={checkedStateOfBet[row.id]}
                                ></PR.Checkbox>
                                <label
                                  htmlFor={row.id}
                                  className="ml-2 font-medium"
                                >
                                  {row.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-2">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            Max bets
                          </span>
                          <div className="card flex mt-1 gap-3">
                            {maxBet?.map((row, index) => (
                              <div className="flex flex-wrap gap-2" key={index}>
                                <div className="flex align-items-center">
                                  <PR.RadioButton
                                    inputId={row.id}
                                    name="bets"
                                    value={row.id}
                                    onChange={(e) => {
                                      maxBetRadioGroupHandler(e.value);
                                    }}
                                    checked={checkedMaxBet === row.id}
                                  />
                                  <label
                                    htmlFor={row.id}
                                    className="ml-2 font-medium"
                                  >
                                    {row.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="flex flex-column gap-2">
                          <span className="label">
                            When is the alert being raised?
                          </span>
                          <div className="card flex mt-1 gap-3">
                            {alertsRaised?.map((row, index) => (
                              <div className="flex flex-wrap gap-2" key={index}>
                                <div className="flex align-items-center">
                                  <PR.Checkbox
                                    inputId={row.id}
                                    value={row.id}
                                    onChange={alertsRaisedCheckboxHandler}
                                    checked={checkedAlertsRaised[row.id]}
                                  ></PR.Checkbox>
                                  <label
                                    htmlFor={row.id}
                                    className="ml-2 font-medium"
                                  >
                                    {row.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {buttonSection()}
                  </div>
                </PR.TabPanel>
              )}
            </PR.TabView>
          </div>
          <div className="col-3 right-section">
            <p className="filters_p">Selected Filters:</p>
            {locationError && <p className="error-message mt-2" style={{color:'red'}}>{locationError}</p>}
            {keywords?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Keyword: </li>
                 
                    <li>
                      <span>
                        {keywords}{" "}
                        <i
                          className="pi pi-times"
                          onClick={()=>{setKeywords('')}}
                        ></i>
                      </span>
                    </li>
                  
                </ul>
              </div>
            )}

            {getSelectedStatus()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Status: </li>
                  {getSelectedStatus()?.map((status, index) => {
                    return (
                      <li key={index}>
                        <span>
                          {status.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() => handleStatusRemove(status)}
                          ></i>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {getSelectedPlayers()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Player / Team: </li>
                  {getSelectedPlayers()?.map((player, index) => {
                    return (
                      <li key={index}>
                        <span>
                          {player.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() => handleRemovePlayerFilter(player)}
                          ></i>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {getSelectedSports()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Sports: </li>
                  {getSelectedSports()?.map((sport, index) => (
                    <li key={index}>
                      <span>
                        {sport.name}{" "}
                        <i
                          className="pi pi-times"
                          onClick={() => handleRemoveSportFilter(sport)}
                        ></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {getSelectedTournamentLevels()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Tournament Levels: </li>
                  {getSelectedTournamentLevels()?.map((tournamentLevel, index) => {
                    return (
                      <li key={index}>
                        <span>
                          {tournamentLevel.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() =>
                              handleRemoveTournamentLevelsFilter(tournamentLevel)
                            }
                          ></i>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {getSelectedAlerts()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Alert type: </li>
                  {getSelectedAlerts()?.map((alert, index) => (
                    <li key={index}>
                      <span>
                        {alert.name}{" "}
                        <i
                          className="pi pi-times"
                          onClick={() => handleRemoveAlertFilter(alert)}
                        ></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {getSelectedResponses()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Response type: </li>
                  {getSelectedResponses()?.map((response, index) => (
                    <li key={index}>
                      <span>
                        {response.name}{" "}
                        <i
                          className="pi pi-times"
                          onClick={() => handleRemoveResponseFilter(response)}
                        ></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {getSelectedCompanies()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Company: </li>
                  {getSelectedCompanies()?.map((company, index) => {
                    return (
                      <li key={index}>
                        <span>
                          {company.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() => handleRemoveCompanyFilter(company)}
                          ></i>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {dateRange && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Date: </li>
                  <li>
                    <span>
                      {dateRange}
                      <i
                        className="pi pi-times"
                        onClick={handleRemoveDateFilter}
                      ></i>
                    </span>
                  </li>
                </ul>
              </div>
            )}
            {getSelectedTournaments()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Tournament / League: </li>
                  {getSelectedTournaments()?.map((tournament, index) => {
                    return (
                      <li key={index}>
                        <span>
                          {tournament.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() =>
                              handleRemoveTournamentFilter(tournament)
                            }
                          ></i>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {getSelectedGenders()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Gender: </li>
                  {getSelectedGenders()?.map((gender, index) => (
                    <li key={index}>
                      <span>
                        {gender.name}{" "}
                        <i
                          className="pi pi-times"
                          onClick={() => handleRemoveGenderFilter(gender)}
                        ></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {getSelectedLocations()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Location: </li>
                  {getSelectedLocations()?.map((location, index) => (
                    <li key={index}>
                      <span>
                        {location.name}{" "}
                        <i
                          className="pi pi-times"
                          onClick={() => handleRemoveLocationFilter(location)}
                        ></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {getSelectedReportTypes()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Report Type: </li>
                  {getSelectedReportTypes()?.map((reportType, index) => (
                    <li key={index}>
                      <span>
                        {reportType.name}{" "}
                        <i
                          className="pi pi-times"
                          onClick={() =>
                            handleRemoveReportTypeFilter(reportType)
                          }
                        ></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {getSelectedReceivers()?.length > 0 && (
              <div className="filters-list mb-3">
                <ul>
                  <li className="block">Receiver: </li>
                  {getSelectedReceivers()?.map((receiver, index) => (
                    <li key={index}>
                      <span>
                        {receiver.name}{" "}
                        <i
                          className="pi pi-times"
                          onClick={() => handleRemoveReceiverFilter(receiver)}
                        ></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {othersTabFiltersSelected() && (
              <div className="filters-list mb-3">
                <p>Other:</p>
                {getSelectedChannels()?.length > 0 && (
                  <ul>
                    <li className="mr-2">Channel </li>
                    {getSelectedChannels()?.map((channel, index) => (
                      <li key={index}>
                        <span>
                          {channel.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() => handleRemoveChannelFilter(channel)}
                          ></i>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
                {getSelectedTimeOfBet()?.length > 0 && (
                  <ul>
                    <li className="mr-2">Time of bet </li>
                    {getSelectedTimeOfBet()?.map((timeOfBet, index) => (
                      <li key={index}>
                        <span>
                          {timeOfBet.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() =>
                              handleRemoveTimeOfBetFilter(timeOfBet)
                            }
                          ></i>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}

                {getSelectedStateOfBets()?.length > 0 && (
                  <ul>
                    <li className="mr-2">State of bet </li>
                    {getSelectedStateOfBets()?.map((stateOfBet, index) => (
                      <li key={index}>
                        <span>
                          {stateOfBet.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() =>
                              handleRemoveStateOfBetFilter(stateOfBet)
                            }
                          ></i>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}

                {getSelectedMaxBets()?.length > 0 && (
                  <ul>
                    <li className="mr-2">Max bet </li>
                    {getSelectedMaxBets()?.map((maxBet, index) => (
                      <li key={index}>
                        <span>
                          {maxBet.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() => handleRemoveMaxBetFilter(maxBet)}
                          ></i>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}

                {getSelectedAlertsRaised()?.length > 0 && (
                  <ul>
                    <li className="mr-2">Alerts Raised </li>
                    {getSelectedAlertsRaised()?.map((alertsRaised, index) => (
                      <li key={index}>
                        <span>
                          {alertsRaised.name}{" "}
                          <i
                            className="pi pi-times"
                            onClick={() =>
                              handleRemoveAlertsRaised(alertsRaised)
                            }
                          ></i>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
