import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import { useFormik } from "formik";
import "./CreateNews.scss";
import { getGroupsWithPermission, getNewsCategories } from "../../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { array, boolean, object, string } from "yup";
import * as Yup from 'yup';
import { fileUploadLimit, maxNumberOfFiles, permission,roleIds } from "../../../../utils/reuse";
import { useToast } from "../../../../context/ToastContext";

const CreateNews = () => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  let fileUploadRef = useRef(null);
  let imageUploadRef = useRef(null); 
  const toast = useRef(null);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [regulators, setRegulators] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  const [governingBodies, setGoverningBodies] = useState([]);
  const [initialValues, setInitialValues] = useState({
    title: "",
    coverImage: null,
    attachments: [],
    description: "",
    keywords: [],
    category: "",
    newsType: state?.category || "",
    pushTo: {
      members: false,
      regulators: [],
      governingBodies: [],
    },
  });
  const newsType = state?.category ?? state?.formvalues?.newsType ?? '';
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
    };
  }, [sessionData.sessionId]);
 
  useEffect(() => {
    if(state?.formvalues){
      setInitialValues(state?.formvalues)
    }
  }, [state?.formvalues])

  useEffect(() => {
    getNewsCategories(headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const result = responseData?.length > 0 ? responseData?.sort((a, b) => a.name.localeCompare(b.name)) : [];
          setCategories(result);
        } else {
          setCategories([]);
        }
      },
      false,
      false
    );

    getGroupsWithPermission(roleIds?.regulator,permission?.news, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setRegulators(result);
      } else {
        setRegulators([]);
      }
    });

    getGroupsWithPermission(roleIds?.governingbody,permission?.news, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData?.sort((a, b) => a.name.localeCompare(b.name)) : [];
        setGoverningBodies(result);
      } else {
        setGoverningBodies([]);
      }
    });
  }, [headers, dispatch, showToast]);

  const validationSchema = object({
    title: string().required("Title is required"),
    description: string().required("Description is required"),
    keywords: array().of(string()),
    coverImage: Yup.mixed()
      .nullable()
      .test('fileSize', `File size must be less than ${fileUploadLimit.label}`, (value) => {
        return !value || value.size <= fileUploadLimit.value; // 50 KB in bytes
      })
      .test('fileType', 'Only image files are allowed', (value) => {
        if (!value) return true; // If no file is provided, return true
        const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return acceptedTypes.includes(value.type);
      }),
    attachments: Yup.array()
      .max(maxNumberOfFiles, `You can upload a maximum of ${maxNumberOfFiles} files`)
      .nullable()
      .test('fileType', 'Only image, PDF, DOCX or XLSX files are allowed', (files) => {
        if (!files) return true;
        const acceptedTypes = [
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'image/jpeg',
          'image/png',
        ];
        return files?.every((file) => acceptedTypes.includes(file.type))
      })
      .test('fileSize', `File size must be less than ${fileUploadLimit.label}`, (files) => {
        if (!files) return true;
        return files?.every((file) => {
          return file.size <= fileUploadLimit.value
        })
      })
      .test('uniqueFileNames', 'Please upload unique file', (value) => {
        if (!value || value.length <= 1) return true;
        const fileNames = value.map((file) => file.name);
        const uniqueFileNames = new Set(fileNames);
        return fileNames.length === uniqueFileNames.size;
      }),
    category: string().when("newsType", {
      is: (value) => value !== "Integrity previews",
      then: (schema) => schema.required("Category is required"),
      otherwise: (schema) => schema,
    }),
    pushTo: object({
      members: boolean(),
      regulators: array().of(string()),
      governingBodies: array().of(string()),
    }).test(
      "pushToValidation",
      "At least one push target is required",
      (value) => {
        const { members, regulators, governingBodies } = value;
        return members || regulators?.length > 0 || governingBodies?.length > 0;
      }
    ),
  });
  

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        title: values.title,
        description: values.description,
        newsType: values.newsType,
        keywords: values.keywords?.join(","),
        categoryName: values.category,
        categoryID: categories?.find(
          (category) => category.name === values.category
        )?.id,
        receiverAllMembers: values.pushTo.members,
        receivers: [
          ...values.pushTo.governingBodies?.map((name) =>
            governingBodies?.find((body) => body.name === name)?.id
          ),
          ...values.pushTo.regulators?.map((name) =>
            regulators?.find((regulator) => regulator.name === name)?.id
          ),
        ],
      };
      const attachments = [];
    
      if (values.attachments?.length > 0) {
        attachments.push(...values.attachments);
      }

      navigate("/preview-news", { state: {formvalues : values, coverImage: values.coverImage ? values.coverImage : '', attachments, payload}});
    },
  });

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
      </span>
    );
  };

  const header = renderHeader();

  const renderCategoryDropdown = () => (
    <PR.Dropdown
      inputId="category"
      name="category"
      value={
        categories?.find((category) => category.name === formik.values.category) ||
        null
      }
      onChange={(e) =>
        formik.setFieldValue("category", e.value ? e.value.name : "")
      }
      options={categories}
      optionLabel="name"
      placeholder="Select a category"
      className="w-full"
    />
  );

  const renderCheckbox = () => (
    <PR.Checkbox
      inputId="members"
      name="members"
      value="All members"
      onChange={(e) => formik.setFieldValue("pushTo.members", e.checked)}
      checked={formik.values.pushTo.members}
    />
  );

  const renderPushToSelect = (placeholder, field, options) => (
    <PR.MultiSelect
      inputId={field}
      value={options?.filter((option) =>
        formik.values.pushTo[field].includes(option.name)
      )}
      onChange={(e) =>
        formik.setFieldValue(
          `pushTo.${field}`,
          e.value ? e.value?.map((v) => v.name) : []
        )
      }
      options={options}
      key={resetKey}
      optionLabel="name"
      filter
      resetFilterOnHide
      placeholder={placeholder}
      maxSelectedLabels={3}
      className="w-full"
      showSelectAll
      onHide={() => {
        setResetKey((prevKey) => prevKey + 1);
      }}
      pt={{
          checkboxContainer: {
              onClick: (e) => {
                  e.stopPropagation();
                  const parent = e.target?.parentNode;
                  if (parent && typeof parent.click === 'function') {
                      parent.click();
                  }
              },
          },
      }}
    />
  );

  const handleFileSelect = (event) => {

    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
    const currentAttachments = formik.values.attachments || [];
    const newAttachment = event.files[0];
  
    const duplicateFiles = currentAttachments.some(existingFile => existingFile.name === newAttachment.name)
  
    if (duplicateFiles) {
      showToast("warn", "File names must be unique.");
      fileUploadRef.current.clear();
    } else if (currentAttachments?.length + 1 > maxNumberOfFiles) {
      showToast("warn", `You can upload a maximum of ${maxNumberOfFiles} files.`);
      fileUploadRef.current.clear();
    } else {
      const updatedAttachments = [...currentAttachments, newAttachment];
      formik.setFieldValue("attachments", updatedAttachments);
    }
  }

  const handleDeleteFile = (index) => {
    const updatedAttachments = [...formik.values.attachments];
    updatedAttachments.splice(index, 1);
    formik.setFieldValue("attachments", updatedAttachments);
  };

  const handleDeleteCoverImage = () => {
    formik.setFieldValue("coverImage", null);
  };

  return (
    <>
      <Layout backBtn={"news"} backBtnLabel="News">
        <section className="news-tab news-details-section general-news-section">
          <div className="flex align-items-center justify-content-between mb-4">
            <div className="left">
              <h1>Create {newsType}</h1>
            </div>
            <div className="right">
              <PR.Button
                label="Save & Preview"
                className="action-buttons save-button"
                type="button"
                onClick={formik.handleSubmit}
              />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid">
              <div className="col-12">
                <div className="new-list-section">
                  <div className="list-wrapper">
                    <div className="edit-details">
                      <div className="card">
                        <div className="col-12 p-0 mb-4">
                          <div className="flex flex-column form-input gap-2">
                            <label htmlFor="title">
                              {newsType === "Sporting sanctions"
                                ? "Sanction title"
                                : "News title"}
                              <span className="important">*</span>
                            </label>
                            <PR.InputText
                              id="title"
                              type="text"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="input-field"
                            />
                            {formik.errors.title && formik.touched.title && (
                              <div className="error-message">{formik.errors.title}</div>
                            )}
                          </div>
                        </div>
                        {newsType === "Sporting sanctions" && (
                          <div className="form-input-row">
                            <div className="col-12 p-0 mb-4">
                              <div className="flex flex-column form-input gap-2">
                                <label htmlFor="keywords">Keywords</label>
                                <PR.Chips
                                  className="chips-field"
                                  inputId="keywords"
                                  value={formik.values.keywords}
                                  onChange={(e) =>
                                    formik.setFieldValue("keywords", e.value)
                                  }
                                  separator=","
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-12 p-0">
                          <div className="flex flex-column gap-2">
                            <span className="label">Attach Cover Image</span>
                            <div className="card create-file-upload flex align-items-center">
                              <PR.FileUpload
                                auto
                                mode="advanced"
                                name="coverImage"
                                accept="image/*"
                                ref={imageUploadRef}
                                chooseLabel="Choose Image"
                                customUpload
                                onSelect={(event) => {
                                  if (imageUploadRef.current) {
                                    imageUploadRef.current.clear();
                                  }
                                  if (formik.values.coverImage) {
                                    formik.setFieldValue(
                                      "coverImage",
                                      event.files[0]
                                    );
                                  } else {
                                    formik.setFieldValue("coverImage", event.files[0]);
                                  }
                                }}
                              />
                              <span className="ml-2">(Add image less than {fileUploadLimit.label})</span>
                            </div>
                          </div>
                          {formik.values.coverImage && (
                            <div className="uploaded-file-container flex flex-column gap-2">
                              <div className="uploaded-files">
                                {formik.values.coverImage.name}
                                <i className="pi pi-trash" onClick={handleDeleteCoverImage} />
                              </div>
                            </div>
                          )}
                          {formik.errors.coverImage && (
                            <div className="error-message" style={{ color: "red" }}>{formik.errors.coverImage}</div>
                          )}
                        </div>
                        <div className="col-12 p-0 mt-4">
                          <div className="flex flex-column gap-2">
                            <span className="label">Add Files</span>
                            <div className="card create-file-upload flex align-items-center">
                              <PR.FileUpload
                                auto
                                mode="advanced"
                                name="attachments"
                                accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png, image/gif"
                                chooseLabel="Choose Files"
                                multiple
                                ref={fileUploadRef}
                                onSelect={handleFileSelect}
                                onFocus={() => formik.setFieldTouched(`attachments`)}
                                customUpload
                              />
                              <span className="ml-2">(Add pdf files: Max limit {maxNumberOfFiles} files)</span>
                            </div>
                          </div>
                          {formik.values.attachments && (
                            <div className="uploaded-file-container flex flex-column gap-2">
                              {formik.values.attachments?.map((attachment, index) => (
                                <div className="uploaded-files" key={index}>
                                  {attachment.name}
                                  <i className="pi pi-trash" onClick={() => handleDeleteFile(index)} />
                                </div>
                              ))}
                            </div>
                          )}
                          {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
                            <div className="error-message" style={{ color: "red" }}>
                              {Array.isArray(formik.errors.attachments) ? (
                                Array.from(new Set(formik.errors?.attachments))?.map((error, index) => (
                                  <div key={index}>{error}</div>
                                ))
                              ) : (
                                <div>{formik.errors.attachments}</div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="col-12 p-0 mt-4">
                          <div className="flex flex-column gap-2">
                            <span className="label">
                              News description
                              <span className="important">*</span>
                            </span>
                            <div className="card content-editor">
                              <PR.Editor
                                name="description"
                                value={formik.values.description}
                                onTextChange={(e) => {
                                  formik.setFieldValue("description", e.htmlValue);
                                }}
                                headerTemplate={header}
                                style={{ height: "450px" }}
                              />
                            </div>
                            {formik.errors.description && formik.touched.description && (
                              <div className="error-message">{formik.errors.description}</div>
                            )}
                          </div>
                        </div>
                        {formik.values.newsType !== "Integrity previews" && (
                          <div className="col-12 p-0 mt-4">
                            <div className="flex flex-column form-input gap-2">
                              <label htmlFor="category">
                                News category
                                <span className="important">*</span>
                              </label>
                              {renderCategoryDropdown()}
                              {formik.errors.category && formik.touched.category && (
                                <div className="error-message">{formik.errors.category}</div>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="grid mt-4">
                          <div className="col-12 pt-0 pb-0">
                            <span className="label">
                              Push news item to
                              <span className="important">*</span>
                            </span>
                          </div>
                          <div className="col-4">
                            <div className="flex form-input gap-2 align-items-center status-box">
                              {renderCheckbox()}
                              <label htmlFor="members" className="ml-2 font-medium">
                                All members
                              </label>
                            </div>
                          </div>
                          <div className="col-4">
                            {renderPushToSelect("Regulators", "regulators", regulators)}
                          </div>
                          <div className="col-4">
                            {renderPushToSelect("Sport Governing Bodies", "governingBodies", governingBodies)}
                          </div>
                        </div>
                        {formik.errors.pushTo && formik.touched.pushTo && (
                          <div className="error-message">{formik.errors.pushTo}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-right mt-4">
                    <PR.Button
                      label="Save & Preview"
                      className="action-buttons save-button"
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </Layout>
      <PR.Toast ref={toast} position="top-right" />
    </>
  );
};

export default CreateNews;
