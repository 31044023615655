import { useState } from "react";
import * as PR from "../../prime-react/index";
import { useFormik } from "formik";
import { addCaseUpdateNotenewAPI } from "../../services/api";
import * as Yup from 'yup';
import { useToast } from "../../context/ToastContext";
import { descriptionCount, getPlainText, textEditorHeader } from "../../utils/reuse";

const AddNote = ({ label, refetch, headers, toast, icon, id }) => {
    const { showToast } = useToast();
    const [postPop, setPostPop] = useState(false);

    const closeHandler = () => setPostPop(false);
    const openHandler = () => setPostPop(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const initialValues = {
        note: ''
    };

    const validationSchema = () => {
        return Yup.object().shape({
            note: Yup.string().required('Note is Required').max(descriptionCount, `Note must be less than or equal to ${descriptionCount} words`)
        })
    }

    const handleSubmit = async (values) => {
        setLoading(true);
        const { result, error } = await addCaseUpdateNotenewAPI(id, { notes: values.note }, headers);
        if (result === 'SUCCESS') {
            refetch(id);            
            formik.resetForm();
            setPostPop(false);
            setLoading(false);
        } else {
            setLoading(false);
            showToast(error.severity, error?.errorMsg || error?.summary);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return <>
        <PR.Button onClick={openHandler} icon={icon} label={label} className="primay-btn" />
        <PR.Dialog className="logout-dialog response-dialog tags-dialog" visible={postPop} blockScroll>
            <div className="dialog-header">
                <div className="gap-2">
                    <h2 className="mb-3">Add Note<span className='important'>*</span></h2>
                    <form id="addNoteForm" onSubmit={formik.handleSubmit}>
                        <PR.Editor
                        placeholder="Explain in detail.."
                        name="note"
                        value={formik.values.note}
                        onTextChange={(e) => {
                            const htmlValue = e.htmlValue;
                            if(getPlainText(htmlValue) === 0 && htmlValue !== null){
                                setErrorMessage('Enter valid text, only spaces are not allowed.');
                            } else if (getPlainText(htmlValue) > descriptionCount) {
                                setErrorMessage(`Comment should be ${descriptionCount} characters or less.`);
                              } else {
                                formik.setFieldValue('note', htmlValue);
                                setErrorMessage("");
                              }
                        }}
                        headerTemplate={textEditorHeader}
                        style={{ height: "200px" }}
                      />  
                        <div className="descriptioncharLimit mt-2">
                            {getPlainText(formik.values.note) <= descriptionCount ? (descriptionCount - getPlainText(formik.values.note)) : 0} /{descriptionCount} characters left
                        </div>
                        <span style={{textAlign:"left"}}>
                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </span>
                        <div className="dialog-footer">
                            <PR.Button label="Cancel" onClick={closeHandler} outlined className="logout-btns" />
                            <PR.Button type="submit" label="Post" outlined autoFocus className="logout-btns click-btn" disabled={loading || errorMessage} loading={loading}/>
                        </div>
                    </form>
                </div>
            </div>
        </PR.Dialog>
    </>
}

export default AddNote;
