import { useEffect, useMemo, useState } from "react";
import * as PR from "../prime-react/index";
import { getFileDetails } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { profileIcon, noGroupImage } from "../assets/images";

const ImageDisplay = ({ profileImage, type }) => {
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const defaultImage = (type === 'group' ? noGroupImage : profileIcon);
    const [displayImage, setDisplayImage] = useState(defaultImage);

    useEffect(() => {
        if (profileImage) {
            const parseImage = JSON.parse(profileImage);
            if (parseImage?.key && (parseImage.type === "image/jpeg" || parseImage.type === "image/jpg" || parseImage.type === "image/png")) {
                getFileDetails(parseImage.key, headers, dispatch, response => {
                    if (response.result === 'SUCCESS') {
                        setDisplayImage(URL.createObjectURL(response?.data) || profileIcon);
                    } else {
                        setDisplayImage(defaultImage);
                    }
                });
            } else {
                setDisplayImage(defaultImage);
            }
        }
          // eslint-disable-next-line
    }, [defaultImage, dispatch, headers, profileImage]);

    return (
        <PR.Image
            src={displayImage}
            className="group-name-icon"
        />
    );
};

export default ImageDisplay;
