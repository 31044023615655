import React from 'react';
import LazyImage from '../../../shared/Alerts/LazyImage';
const icons = {
    '5a': <LazyImage src="1/15.png" alt="Badminton" />,
    '5b': <LazyImage src="2/15.png" alt="Badminton" />,

    '8a': <LazyImage src="1/18.png" alt="Basketball" />,
    '8b': <LazyImage src="2/18.png" alt="Basketball" />,

    '11a': <LazyImage src="1/21.png" alt="Beach volleyball" />,
    '11b': <LazyImage src="2/21.png" alt="Beach volleyball" />,

    '14a': <LazyImage src="1/24.png" alt="Bowls" />,
    '14b': <LazyImage src="2/24.png" alt="Bowls" />,

    '15a': <LazyImage src="1/25.png" alt="Boxing" />,
    '15b': <LazyImage src="2/25.png" alt="Boxing" />,

    '18a': <LazyImage src="1/28.png" alt="Combat Sports" />,
    '18b': <LazyImage src="2/28.png" alt="Combat Sports" />,

    '19a': <LazyImage src="1/29.png" alt="Cricket" />,
    '19b': <LazyImage src="2/29.png" alt="Cricket" />,

    '1a': <LazyImage src="1/43.png" alt="Football" />,
    '1b': <LazyImage src="2/43.png" alt="Football" />,

    '34a': <LazyImage src="1/45.png" alt="Futsal" />,
    '34b': <LazyImage src="2/45.png" alt="Futsal" />,

    '37a': <LazyImage src="1/48.png" alt="Greyhounds" />,
    '37b': <LazyImage src="2/48.png" alt="Greyhounds" />,

    '39a': <LazyImage src="1/50.png" alt="Handball" />,
    '39b': <LazyImage src="2/50.png" alt="Handball" />,

    '41a': <LazyImage src="1/52.png" alt="Horses" />,
    '41b': <LazyImage src="2/52.png" alt="Horses" />,

    '42a': <LazyImage src="1/53.png" alt="Ice hockey" />,
    '42b': <LazyImage src="2/53.png" alt="Ice hockey" />,

    '43a': <LazyImage src="1/54.png" alt="Indoor Soccer" />,
    '43b': <LazyImage src="2/54.png" alt="Indoor Soccer" />,

    '60a': <LazyImage src="1/71.png" alt="Pool" />,
    '60b': <LazyImage src="2/71.png" alt="Pool" />,

    '82a': <LazyImage src="1/93.png" alt="Squash" />,
    '82b': <LazyImage src="2/93.png" alt="Squash" />,

    '83a': <LazyImage src="1/94.png" alt="Swimming" />,
    '83b': <LazyImage src="2/94.png" alt="Swimming" />,

    '85a': <LazyImage src="1/96.png" alt="Table Tennis" />,
    '85b': <LazyImage src="2/96.png" alt="Table Tennis" />,

    '87a': <LazyImage src="1/98.png" alt="Tennis" />,
    '87b': <LazyImage src="2/98.png" alt="Tennis" />,

    '90a': <LazyImage src="1/101.png" alt="Volleyball" />,
    '90b': <LazyImage src="2/101.png" alt="Volleyball" />,

    '93a': <LazyImage src="1/104.png" alt="eSports" />,
    '93b': <LazyImage src="2/104.png" alt="eSports" />,
};

export default icons;