import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { handlePolicyDownload, privacyPolicyLink, supportEmail, termsAndConditionsLink } from '../utils/reuse';
import { useSelector } from 'react-redux';

const Footer = () => {
  const [year] = useState(new Date().getFullYear())
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  return (
    <>{isLoggedIn ?
        <footer>
          <div className='footer-section'>
            <div className="grid align-items-center">
                <div className="col-6">
                  <p><Link to="https://ibia.bet" target='_blank'>All Rights reserved. &copy;{year} | IBIA</Link></p>
                </div>
                <div className="col-6 text-right">
                    <ul className="flex list-none align-items-center justify-content-end">
                      <li><Link to="https://bluehouse-technology.co.uk" target='_blank'>Developed by Bluehouse Technology</Link></li>
                      <li className="line"> | </li>
                      <li>
                        <a 
                          href={privacyPolicyLink} 
                          onClick={(e) => handlePolicyDownload(e, privacyPolicyLink, 'IBIA_Privacy_Notice.pdf')}
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li className="line"> | </li>
                      <li>
                        <a 
                          href={termsAndConditionsLink} 
                          onClick={(e) => handlePolicyDownload(e, termsAndConditionsLink, 'IBIA_Code_of_Conduct.pdf')}
                        >
                          Code of Conduct
                        </a>
                      </li>
                    </ul>
                </div>
            </div>
          </div>
        </footer> : <div className="login-footer">
                    <ul className="flex list-none align-items-center justify-content-between">
                        <div className='left'>
                          <p><Link to="https://ibia.bet" target='_blank'>All Rights reserved. &copy;{year} | IBIA</Link></p>
                        </div>
                        <div className="right flex list-none align-items-center ">
                          <li><Link to="https://bluehouse-technology.co.uk" target='_blank'>Developed by Bluehouse Technology</Link></li>
                          <li className="line"> | </li>
                          <li><Link to={`mailto:${supportEmail}`}>Contact</Link></li>
                          <li className="line"> | </li>
                          <li><a href={termsAndConditionsLink} onClick={(e) => handlePolicyDownload(e, termsAndConditionsLink, 'IBIA_Code_of_Conduct.pdf')}>Code of Conduct</a></li>
                          <li className="line"> | </li>
                          <li><a href={privacyPolicyLink} onClick={(e) => handlePolicyDownload(e, privacyPolicyLink, 'IBIA_Privacy_Notice.pdf')}>Privacy</a></li>
                        </div>
                    </ul>
                </div> }
    </>
  )
}
export default Footer