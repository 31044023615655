import secureLocalStorage from "react-secure-storage";

// Convert Blob (image) to Base64 string
const imageBlobToBase64 = (imageBlob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(imageBlob); // This converts Blob to Base64
  });
};

// Store Base64 image in secure storage
export const storeImageBlob = async (key, imageBlob) => {
  if(imageBlob === null){
    secureLocalStorage.setItem(key, null);
    return
  }
  const base64Image = await imageBlobToBase64(imageBlob);
  secureLocalStorage.setItem(key, base64Image);
};


// Function to retrieve the image Blob from secure storage
export const retrieveImageBlob = (key) => {
    const base64Image = secureLocalStorage.getItem(key);
    if (base64Image) {
      // Decode Base64 string back to Blob
      const byteString = atob(base64Image.split(",")[1]);
      const mimeType = base64Image.split(",")[0].split(":")[1].split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);
      
      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }
  
      return new Blob([arrayBuffer], { type: mimeType });
    }
    return null;
};
  