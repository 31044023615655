import { createSlice } from "@reduxjs/toolkit";

// Slice for alerts filter
const alertFilterSlice = createSlice({
  name: 'alertFilter',
  initialState: {},
  reducers: {
    setFilter(state, action) {
      return action.payload;
    },
    resetFilter() {
      return {};
    }
  }
});

// Slice for reports filter
const reportFilterSlice = createSlice({
  name: 'reportFilter',
  initialState: {},
  reducers: {
    setReportFilter(state, action) {
      return action.payload;
    },
    resetReportFilter() {
      return {};
    }
  }
});

export const { setFilter, resetFilter } = alertFilterSlice.actions;
export const { setReportFilter, resetReportFilter } = reportFilterSlice.actions;

export const alertFilterReducer = alertFilterSlice.reducer;
export const reportFilterReducer = reportFilterSlice.reducer;