
import React from "react";
import * as PR from "../../prime-react/index";

const MostViewed = ({ mostViewedData, imageMap }) => {
  return (
    <div className="new-view-section">
      <div className="header-section">
        <h3>
          <i className="pi pi-star"></i> Most viewed
        </h3>
      </div>
      <div className="viewed-list">
        {mostViewedData.map((item) => (
          <div key={item.id} className="flex align-items-center gap-3 mb-3">
            <div className="news-img">
              <PR.Image src={imageMap[item.image]} alt={item.title} />
            </div>
            <div className="news-heading">
              <h3>{item.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MostViewed;
