import React, {
  useEffect,
  useState,
  useMemo,
  useCallback
} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./NewsCategory.scss";
import Layout from "../../layouts/Layout";
import * as PR from "../../prime-react/index";
import { Avatar } from "../../assets/images/index";
import { getNewsByCategory } from "../../services/api";
import "../../shared/TopCategories/TopCategories.scss";
import { pagecount } from "../../utils/reuse";
import TopCategories from "../../shared/TopCategories/TopCategories";
import NewsItem from "../../shared/News/NewsItem";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useToast } from "../../context/ToastContext";

const NewsCategory = () => {
  const { showToast } = useToast();
  const [dates, setDates] = useState(null);
  const [filteredNewsData, setFilteredNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const categoryId = params.get("categoryID");
  const categoryName = params.get("categoryName")?.replace(/-/g, " ");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(
    () => ({ sessionid: sessionData.sessionId }),
    [sessionData.sessionId]
  );
  const pageCount = pagecount;

  useEffect(() => {
    if (
      searchQuery?.length >= 3 ||
      (dates && dates?.length > 0) ||
      (searchQuery?.length === 0 && (!dates || dates?.length === 0))
    ) {
      setCurrentPage(1);
      fetchNewsByCategory(1);
    }
    // eslint-disable-next-line
  }, [categoryId, searchQuery, dates]);

  const fetchNewsByCategory = useCallback(
    (page = 1) => {
      const obj = {
        offset: (page - 1) * pageCount,
        limit: pageCount,
        search: searchQuery?.length >= 3 ? searchQuery : undefined,
      };
      if (dates && dates?.length > 0) {
        const startDate = moment(dates[0]).format("YYYY-MM-DD");
        const endDate = dates[1]
          ? moment(dates[1]).format("YYYY-MM-DD")
          : startDate;
        obj.startDate = startDate;
        obj.endDate = endDate;
      }

      getNewsByCategory(categoryId, obj, headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const totalPages = Math.ceil(responseData?.length / pageCount);
          setFilteredNewsData(responseData);
          setTotalPages(totalPages);
        } else {
          const error = response.error;
          showToast(error.severity, error?.errorMsg || error?.summary);
        }
        setLoading(false);
      });
    },
    // eslint-disable-next-line
    [categoryId, headers, dispatch, searchQuery, dates]
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      fetchNewsByCategory(newPage);
    }
  };

  const handleCategoryClick = (categoryId, categoryName) => {
    const formattedCategory = categoryName
      ?.replace(/ /g, "-")
      ?.replace(/-+$/, "");
    navigate(
      `/news-category?categoryID=${categoryId}&categoryName=${formattedCategory}`
    );
  };

  return (
    <>
      <Layout>
        <div className="back-button">
          <Link to="/news">
            <i className="pi pi-arrow-left"></i>
            Back to News
          </Link>
        </div>
        <section className="news-tab">
          <div className="grid">
            <div className="col-9">
              <div className="new-list-section">
                <div className="flex align-items-center justify-content-between">
                  <div className="left">
                    <h1>{categoryName}</h1>
                  </div>
                  <div className="right flex gap-3">
                    <div className="flex align-items-center search-bar">
                      <span className="p-input-icon-left w-12">
                        <i className="pi pi-search" />
                        <PR.InputText
                          placeholder="Search.."
                          className="search-box w-12"
                          value={searchQuery}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value?.length <= 15) {
                              setSearchQuery(value);
                            }
                          }}
                        />
                      </span>
                    </div>
                    <div className="card" style={{ position: "relative" }}>
                      <PR.Calendar
                        value={dates}
                        onChange={(e) => setDates(e.value)}
                        iconPos="left"
                        readOnlyInput
                        showIcon
                        selectionMode="range"
                        className="date-calendar"
                        placeholder="Select Date"
                        showButtonBar
                        dateFormat="dd/mm/yy"
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
                <div className="list-wrapper">
                  {loading ? (
                    <div className="loading">
                      <i className="pi pi-spin pi-spinner"></i>
                    </div>
                  ) : !filteredNewsData || filteredNewsData?.length === 0 ? (
                    <div className="flex justify-content-center">
                      <p className="no-data m-0 text-bluegray-500">
                        No records found
                      </p>
                    </div>
                  ) : (
                    filteredNewsData?.map((news) => (
                      <React.Fragment key={news.id}>
                        <NewsItem
                          news={news}
                          Avatar={Avatar}
                          newsType="general"
                        />
                        <div className="hr-line mt-4 mb-4"></div>
                      </React.Fragment>
                    ))
                  )}
                </div>
                {filteredNewsData?.length > 0 && (
                  <div className="pagination">
                    <div className="grid grid-nogutter align-items-center justify-content-between">
                      <span>Page {currentPage}</span>
                      <ul className="flex list-none align-items-center gap-3">
                        <li
                          className={currentPage === 1 ? "disabled" : ""}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </li>
                        <li
                          className={
                            currentPage === totalPages ? "disabled" : ""
                          }
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-3">
              <TopCategories
                heading={"Other Categories"}
                handleCategoryClick={handleCategoryClick}
              />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default NewsCategory;
