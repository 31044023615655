import { useState, useEffect, useMemo } from "react";
import * as PR from "../prime-react/index";
import { getFileDetails } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { profile, profileIcon } from "../assets/images";
import { retrieveImageBlob, storeImageBlob } from "../utils/profileImage";
import secureLocalStorage from "react-secure-storage";

const ProfileImageDisplay = ({ profileImage }) => {
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const profileData = useSelector(state => state.auth.profileData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [displayImage, setDisplayImage] = useState();

    const blobKey = secureLocalStorage.getItem("userImage")

    useEffect(() => {
        if(blobKey){
            const blob = retrieveImageBlob("userImage")
            setDisplayImage(URL.createObjectURL(blob));
        }else if (!blobKey && profileImage) {
            if (profileImage?.key && (profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" || profileImage.type === "image/png")) {
                getFileDetails(profileImage.key, headers, dispatch, response => {
                    if (response.result === 'SUCCESS') {
                        setDisplayImage(URL.createObjectURL(response?.data));
                        storeImageBlob("userImage", response?.data); 
                    }
                });
            } 
        }else{
            setDisplayImage(profileIcon)
        }
    }, [blobKey, dispatch, headers, profileData?.profilePic, profileImage]);

    return (
        displayImage ? <PR.Image
            src={displayImage}
            className="group-name-icon"
        />
        :
        <PR.Image
            src={profile}
            className="group-name-icon"
        />
    );
};

export default ProfileImageDisplay;
