import { configureStore } from "@reduxjs/toolkit";
import authReducer from './auth'
import newsReducer from './news'
import headerReducer from './Header'
import { alertFilterReducer, reportFilterReducer } from "./filter.slice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        news: newsReducer,
        headerMenu: headerReducer,
        alertFilter: alertFilterReducer,
        reportFilter: reportFilterReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
})

export default store;