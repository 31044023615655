import React from "react";
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import '../CreateReport/CreateReport.scss'
import { newsDetails } from "../../../../assets/images/index";

const DownloadReport = () => {
  
    return (
        <>
            <Layout>
                <section className="news-tab news-details-section general-news-section">
                    <div className="flex align-items-center justify-content-between mb-4">
                        <div className="left">
                            <h1>Unusual Activity in Matches</h1>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className='new-list-section'>
                                <div className='list-wrapper'>

                                    <div className="news-list preview-report">
                                        <div className='relative'>
                                            <PR.Image src={newsDetails} alt="Weekly Integrity Preview" className="report-img" />
                                            <div className='overlay'>
                                                <h2>Suspicious betting</h2>
                                            </div>
                                        </div>
                                        <div className='attachments flex align-items-center justify-content-between mt-3'>
                                            <PR.Button icon='pi pi-book' label='secondin90000sheet.pdf' className="download-button" />
                                            <PR.Button icon='pi pi-download' label='Download Report' className="save-button" />
                                        </div>
                                        <div className="col-12 p-0 mt-3">
                                            <div className='content'>
                                                <p>New Betting Regulations to Enhance Consumer Protection. In response to the evolving landscape of online betting, regulatory authorities have introduced comprehensive amendments to the existing betting regulations aimed at fortifying consumer protection measures. The updated regulations, set to come into effect next quarter, emphasize a greater focus on ensuring fairness, transparency, and responsible gambling practices within the betting industry.</p>
                                                <p>These revisions encompass stringent guidelines for licensed betting operators, emphasizing the need for robust age verification processes, enhanced customer identity checks, and measures to prevent excessive gambling behaviors. </p>
                                                <p>Additionally, the updated regulations mandate clear and concise disclosure of terms and conditions, ensuring customers have access to transparent information regarding odds, payouts, and potential risks associated with betting activities. These revisions encompass stringent guidelines for licensed betting operators, emphasizing the need for robust age verification processes, enhanced customer identity checks, and measures to prevent excessive gambling behaviors. Additionally, the updated regulations mandate clear and concise disclosure of terms and conditions, ensuring customers have access to transparent information regarding odds, payouts, and potential risks associated with betting activities.</p>
                                            </div>
                                            <div className='attachments flex gap-3 mt-3'>
                                                <span className="type-info">Report Type: Suspicious betting</span>
                                                <span className="type-info">Sport: Tennis</span>
                                            </div>
                                            <div className='card mt-5'>
                                                <ul className="list-none gap-4 flex align-items-center justify-content-between mb-0">
                                                    <li><i className="pi pi-calendar"></i> <span>29 mar 2024</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default DownloadReport;