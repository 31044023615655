import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import BackMenuLayout from "../layouts/BackMenuLayout";
import {  useEffect, useMemo } from "react";
import { getValidRouteName } from "../utils/reuse";
const saveLastVisitedPath = (pathname) => {
    const dontSavePaths = ['/', '/login', '/otp', '/forgot-password', '/set-password', '/reset-password', '/page-not-found'];
    if (pathname && !dontSavePaths.includes(pathname)) {
        localStorage.setItem("lastVisitedPath", pathname);
    }
};
export const PublicRoute = () => {
    const isAuthenticated = useSelector(state => state.auth.isLoggedIn);
    const isLogout = useSelector(state => state.auth.isLogout);
    const location = useLocation();
    const fullPath = `${location.pathname}${location.search}`;
    useMemo(() => {
        if(!isAuthenticated && !isLogout){
            saveLastVisitedPath(fullPath);
        } 
      }, [fullPath, isAuthenticated, isLogout]);
    return isAuthenticated ? <Navigate to='/home' /> : <Outlet />;
}

export const PrivateRoute = () => {
    const isAuthenticated  = useSelector(state => state.auth.isLoggedIn)
    const location = useLocation();
    const navigate = useNavigate();
    const fullPath = `${location.pathname}${location.search}`;
    const isValidUrl = getValidRouteName(fullPath, 'authenticated');
    useEffect(() => {
        if(isValidUrl){
            navigate(isValidUrl)
        }
    }, [isValidUrl, navigate])
    
    return isAuthenticated ? <BackMenuLayout ><Outlet /></BackMenuLayout>: <Navigate to='/login' />;
}
