import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import * as PR from "../prime-react/index";
import { toastDisplayTime } from '../utils/reuse';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);
  const [errorMessage, setErrorMessage] = useState({});
  const showToast = useCallback((severity, messageId, key) => {
    const toastKeys = {
      "success": "pi pi-check-circle",
      "error": "pi pi-times-circle",
      "info": "pi pi-info-circle",
      "warn": "pi pi-exclamation-circle"
    }
    const icon = toastKeys[severity];
    if (severity === 401) return;

    if (severity === 'error') {
      setErrorMessage(prev => ({ ...prev, [key]: messageId }));
      return
    }
    if (toast.current) {
      toast.current.clear();
      toast.current.show({
        life: toastDisplayTime,
        severity: severity,
        summary: messageId ?? 'Something went wrong',
        icon: icon, // Custom icon
        className: 'custom-toast',
      });
    }
  }, []);

  const clearError = useCallback((k) => {
    setErrorMessage(p => { const v = { ...p }; delete v[k]; return v; })
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, errorMessage, clearError }}>
      <PR.Toast ref={toast} position="bottom-center" baseZIndex={9999} />
      {children}
    </ToastContext.Provider>
  );
};

export const ErrorMessage = ({ id }) => 
<ToastContext.Consumer>
  {({ errorMessage }) => <p style={{ textAlign: 'left', fontSize:12, fontWeight:400, color:'#D04A09'}} className='error-message' >{errorMessage[id]}</p>}
</ToastContext.Consumer>

export const useToast = () => useContext(ToastContext)