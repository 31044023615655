import defaultReport from "../assets/images/reports/default.jpg";
import Basketball from "../assets/images/reports/8.jpg";
import Soccer from "../assets/images/reports/78.jpg";
import Horses from "../assets/images/reports/41.jpg";
import TableTennis from "../assets/images/reports/85.jpg";
import Tennis from "../assets/images/reports/87.jpg";

export const reportSportImages = {
  8: Basketball,
  41: Horses,
  78: Soccer,
  85: TableTennis,
  87: Tennis
}

export const getImageForReport = (report) => {
    if (report?.reportType === "summary") {
      return defaultReport;
    }
    return reportSportImages[report?.sportID] || defaultReport;
  };