
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../auth/Login/Login';
import OTP from '../auth/OTP/OTP';
import ForgotPassword from '../auth/ForgotPassword/ForgotPassword';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import { PublicRoute } from './commonRoute';
import SetPassword from '../auth/SetPassword';

const PublicRoutes = () => {

    return <Routes>
        <Route element={<PublicRoute />} >
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/otp' element={<OTP />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/set-password' element={<SetPassword />} />
            <Route path='/reset-password' element={<SetPassword />} />
            <Route path='/page-not-found' element={<PageNotFound />} />
            <Route path='*' element={<Navigate to='/login' />} />
        </Route>
    </Routes>


}

export default PublicRoutes