import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import * as PR from "../../../prime-react/index";
import Layout from '../../../layouts/Layout';
import { Avatar } from "../../../assets/images/index";
import { getNews, deleteNews } from "../../../services/api";
import "./NewsAndUpdates.scss";
import NewsItem from '../../../shared/News/NewsItem';
import CreateNewsDialog from './NewsDialog/CreateNewsDialog';
import { pagecount, sortByCreatedAt } from '../../../utils/reuse';
import { useToast } from '../../../context/ToastContext';

const NewsAndUpdates = () => {
    const { showToast } = useToast();
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [createNewsDialogVisible, setCreateNewsDialogVisible] = useState(false);

    const newsTabIndex = useSelector(state => state.news.activeIndex)
    const [selectedTab, setSelectedTab] = useState(newsTabIndex); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); 
    const [deletedNewsIds, setDeletedNewsIds] = useState([]);
    const pageCount = pagecount;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);

    const fetchNews = useCallback((page = 1) => {
        setLoading(true);
        const obj = {
            offset: (page - 1) * pageCount,
            limit: pageCount,
            type: selectedTab === 0 ? '1' : selectedTab === 2 ? '2' : '3',
        };
        getNews(obj, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data?.length ? sortByCreatedAt(response.data) : [];
                const totalPages = Math.ceil(responseData?.length / pageCount);
                setNewsData(responseData);
                setTotalPages(totalPages);
            } else {
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
            setLoading(false);
        });
        // eslint-disable-next-line
    }, [selectedTab, headers, dispatch]);

    const getTabId = (newsType) => {
        switch (newsType) {
            case "generalnews":
                return 0;
            case "integritypreviews":
                return 1;
            case "sportingsanctions":
                return 2;
            default:
                return 0;
        }
    }

    const [selectedNews, setSelectedNews] = useState({})

    const handleDeleteNews = (id, newsType) => {
        setSelectedNews({id:id, newsType:newsType})
        setShowDeleteDialog(true)
    }

    const handleDelete = () => {
        setSelectedTab(getTabId(selectedNews.newsType))
        setLoading(true);
        deleteNews(selectedNews.id, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS') {
                fetchNews(currentPage);
                setDeletedNewsIds([...deletedNewsIds, selectedNews.id]);
                showToast("success", "News deleted successfully");
            } else {
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
            setSelectedNews({})
            setShowDeleteDialog(false);
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchNews(currentPage);
        // eslint-disable-next-line
    }, [selectedTab, currentPage, fetchNews]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSelectCategory = (category) => {
        setCreateNewsDialogVisible(false);
        if (category) {
            navigate("/create-news", { state: { category } });
        }
    };

    const handleCNewsCategoriesClick = () => {
        navigate("/news-categories");
    };

    const paginatedNewsData = newsData?.slice(
        (currentPage - 1) * pageCount,
        currentPage * pageCount
    );
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    return (
        <>
              <Layout backBtn={"home"} backBtnLabel="Home">
                <section className="all-alerts-section all-tags-section mt-5">
                    <div className="m-0">
                        <h1>News & Updates</h1> 
                    </div>
                    <div className="alerts-tab news-updates-section">
                        <PR.TabView activeIndex={selectedTab} onTabChange={(e) => setSelectedTab(e.index)}>
                            <PR.TabPanel header="General news">
                                <div className="news-tab general-news-tab">
                                    <div className="filter-section flex gap-2">
                                        <PR.Button label="Create News" icon="pi pi-plus" className="create-button" onClick={() => setCreateNewsDialogVisible(true)} />
                                        <PR.Button label="News Categories" icon="pi pi-sign-in" onClick={handleCNewsCategoriesClick} iconPos='right' className="info-button categories-button" />
                                    </div>
                                    <div className='new-list-section'>
                                        <div className='list-wrapper'>
                                            {loading ? (
                                                <div className="loading new-updates-loading">
                                                    <i className="pi pi-spin pi-spinner"></i>
                                                </div>
                                            ) : (
                                                (paginatedNewsData?.length > 0)
                                                ? (paginatedNewsData?.map((news, index) => (
                                                    <React.Fragment key={news.id}>
                                                        <NewsItem 
                                                            news={news} 
                                                            Avatar={Avatar} 
                                                            isMember={false} 
                                                            newsType="generalnews" 
                                                            handleDeleteNews={handleDeleteNews} 
                                                            fetchNews={fetchNews}
                                                            deletedNewsIds={deletedNewsIds}
                                                        />
                                                        <div className="hr-line my-4"></div>
                                                    </React.Fragment>
                                                )))
                                                :(<p>No news created yet</p>)
                                            )}
                                        </div>
                                        {/* Pagination Section */}
                                        {newsData?.length > 0 && (
                                            <div className="pagination">
                                                <div className="grid grid-nogutter align-items-center justify-content-between">
                                                    <span>Page {currentPage} </span>
                                                    <ul className="flex list-none align-items-center gap-2">
                                                        <li
                                                            className={currentPage === 1 ? "disabled" : ""}
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                        >
                                                            Previous
                                                        </li>
                                                        <li
                                                            className={
                                                                currentPage === totalPages ? "disabled" : ""
                                                            }
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                        >
                                                            Next
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </PR.TabPanel>
                            <PR.TabPanel header="Integrity previews">
                                <div className="news-tab general-news-tab">
                                    <div className="filter-section flex gap-2">
                                        <PR.Button label="Create News" icon="pi pi-plus" className="create-button" onClick={() => setCreateNewsDialogVisible(true)} />
                                    </div>
                                    <div className='new-list-section'>
                                        <div className='list-wrapper'>
                                            {loading ? (
                                                <div className="loading new-updates-loading">
                                                    <i className="pi pi-spin pi-spinner"></i>
                                                </div>
                                            ) : (
                                                (paginatedNewsData?.length>0)
                                                ?(paginatedNewsData?.map((news, index) => (
                                                    <React.Fragment key={news.id}>
                                                        <NewsItem 
                                                            news={news} 
                                                            Avatar={Avatar} 
                                                            isMember={false} 
                                                            newsType="integritypreviews" 
                                                            handleDeleteNews={handleDeleteNews} 
                                                            fetchNews={fetchNews}
                                                            deletedNewsIds={deletedNewsIds}
                                                        />
                                                        <div className="hr-line my-4"></div>
                                                    </React.Fragment>
                                                )))
                                                :(<p>No news created yet</p>)
                                            )}
                                        </div>
                                        {/* Pagination Section */}
                                        {newsData?.length > 0 && (
                                            <div className="pagination">
                                                <div className="grid grid-nogutter align-items-center justify-content-between">
                                                    <span>Page {currentPage} </span>
                                                    <ul className="flex list-none align-items-center gap-3">
                                                        <li
                                                            className={currentPage === 1 ? "disabled" : ""}
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                        >
                                                            Previous
                                                        </li>
                                                        <li
                                                            className={
                                                                currentPage === totalPages ? "disabled" : ""
                                                            }
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                        >
                                                            Next
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </PR.TabPanel>
                            <PR.TabPanel header="Sporting sanctions">
                                <div className="news-tab general-news-tab">
                                    <div className="filter-section flex gap-2">
                                        <PR.Button label="Create News" icon="pi pi-plus" className="create-button" onClick={() => setCreateNewsDialogVisible(true)} />
                                    </div>
                                    <div className='new-list-section'>
                                        <div className='list-wrapper'>
                                            {loading ? (
                                                <div className="loading new-updates-loading">
                                                    <i className="pi pi-spin pi-spinner"></i>
                                                </div>
                                            ) : (
                                                (paginatedNewsData?.length>0)
                                                ?(paginatedNewsData?.map((news, index) => (
                                                    <React.Fragment key={news.id}>
                                                        <NewsItem 
                                                            news={news} 
                                                            Avatar={Avatar} 
                                                            isMember={false} 
                                                            newsType="sportingsanctions" 
                                                            handleDeleteNews={handleDeleteNews} 
                                                            fetchNews={fetchNews}
                                                            deletedNewsIds={deletedNewsIds}
                                                        />
                                                        <div className="hr-line my-4"></div>
                                                    </React.Fragment>
                                                )))
                                                :(<p>No news created yet</p>)
                                            )}
                                        </div>
                                        {/* Pagination Section */}
                                        {newsData?.length > 0 && (
                                            <div className="pagination">
                                                <div className="grid grid-nogutter align-items-center justify-content-between">
                                                    <span>Page {currentPage}</span>
                                                    <ul className="flex list-none align-items-center gap-3">
                                                        <li
                                                            className={currentPage === 1 ? "disabled" : ""}
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                        >
                                                            Previous
                                                        </li>
                                                        <li
                                                            className={
                                                                currentPage === totalPages ? "disabled" : ""
                                                            }
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                        >
                                                            Next
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </PR.TabPanel>
                        </PR.TabView>
                    </div>
                </section>
                <PR.Dialog
                    visible={showDeleteDialog}
                    className="news-dialog"
                    header="Delete news"
                    modal
                    closable={false}
                    footer={
                        <>
                        <PR.Button
                        label="Cancel"
                        className="p-button-text custom-cancel-button"
                        onClick={() => setShowDeleteDialog(false)}
                        />
                        <PR.Button
                        label="Delete"
                        className="p-button-danger custom-delete-button"
                        onClick={handleDelete}
                        />
                    </>
                    }
                >
                    <div className="confirmation-content">
                        <span>Are you sure you want to delete this news?</span>
                    </div>
                </PR.Dialog>
            </Layout>
            <CreateNewsDialog 
                visible={createNewsDialogVisible} 
                onHide={() => setCreateNewsDialogVisible(false)} 
                onSelectCategory={handleSelectCategory} 
            />
        </>
    );
};

export default NewsAndUpdates;
